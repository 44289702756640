/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBrandPositioning = /* GraphQL */ `
  query GetBrandPositioning($id: ID!) {
    getBrandPositioning(id: $id) {
      id
      positioning
      messaging
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrandPositionings = /* GraphQL */ `
  query ListBrandPositionings(
    $filter: ModelBrandPositioningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandPositionings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        positioning
        messaging
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLLMJob = /* GraphQL */ `
  query GetLLMJob($id: ID!) {
    getLLMJob(id: $id) {
      id
      status
      result
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLLMJobs = /* GraphQL */ `
  query ListLLMJobs(
    $filter: ModelLLMJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLLMJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        result
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestions = /* GraphQL */ `
  query GetQuestions($id: ID!) {
    getQuestions(id: $id) {
      id
      method
      options
      question
      projectbriefID
      Answers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        method
        options
        question
        projectbriefID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const questionsByProjectbriefID = /* GraphQL */ `
  query QuestionsByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        method
        options
        question
        projectbriefID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnswers = /* GraphQL */ `
  query GetAnswers($id: ID!) {
    getAnswers(id: $id) {
      id
      answer
      questionsID
      panelistID
      projectbriefID
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        answer
        questionsID
        panelistID
        projectbriefID
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const answersByQuestionsID = /* GraphQL */ `
  query AnswersByQuestionsID(
    $questionsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByQuestionsID(
      questionsID: $questionsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        questionsID
        panelistID
        projectbriefID
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const answersByPanelistID = /* GraphQL */ `
  query AnswersByPanelistID(
    $panelistID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByPanelistID(
      panelistID: $panelistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        questionsID
        panelistID
        projectbriefID
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const answersByProjectbriefID = /* GraphQL */ `
  query AnswersByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        questionsID
        panelistID
        projectbriefID
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const answersByCustomerpersonaID = /* GraphQL */ `
  query AnswersByCustomerpersonaID(
    $customerpersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByCustomerpersonaID(
      customerpersonaID: $customerpersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        questionsID
        panelistID
        projectbriefID
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConceptAnswers = /* GraphQL */ `
  query GetConceptAnswers($id: ID!) {
    getConceptAnswers(id: $id) {
      id
      answer
      conceptquestionsID
      panelistID
      projectbriefID
      customerpersonaID
      conceptsID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConceptAnswers = /* GraphQL */ `
  query ListConceptAnswers(
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConceptAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptAnswersByConceptquestionsID = /* GraphQL */ `
  query ConceptAnswersByConceptquestionsID(
    $conceptquestionsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptAnswersByConceptquestionsID(
      conceptquestionsID: $conceptquestionsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptAnswersByPanelistID = /* GraphQL */ `
  query ConceptAnswersByPanelistID(
    $panelistID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptAnswersByPanelistID(
      panelistID: $panelistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptAnswersByProjectbriefID = /* GraphQL */ `
  query ConceptAnswersByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptAnswersByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptAnswersByCustomerpersonaID = /* GraphQL */ `
  query ConceptAnswersByCustomerpersonaID(
    $customerpersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptAnswersByCustomerpersonaID(
      customerpersonaID: $customerpersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptAnswersByConceptsID = /* GraphQL */ `
  query ConceptAnswersByConceptsID(
    $conceptsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptAnswersByConceptsID(
      conceptsID: $conceptsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        conceptquestionsID
        panelistID
        projectbriefID
        customerpersonaID
        conceptsID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConceptQuestions = /* GraphQL */ `
  query GetConceptQuestions($id: ID!) {
    getConceptQuestions(id: $id) {
      id
      method
      options
      question
      projectbriefID
      conceptAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConceptQuestions = /* GraphQL */ `
  query ListConceptQuestions(
    $filter: ModelConceptQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConceptQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        method
        options
        question
        projectbriefID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptQuestionsByProjectbriefID = /* GraphQL */ `
  query ConceptQuestionsByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptQuestionsByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        method
        options
        question
        projectbriefID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConcepts = /* GraphQL */ `
  query GetConcepts($id: ID!) {
    getConcepts(id: $id) {
      id
      description
      projectbriefID
      likeStatus
      userFeedback
      image
      video
      conceptAnswerss {
        nextToken
        __typename
      }
      title
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        projectbriefID
        likeStatus
        userFeedback
        image
        video
        title
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptsByProjectbriefID = /* GraphQL */ `
  query ConceptsByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptsByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        projectbriefID
        likeStatus
        userFeedback
        image
        video
        title
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptsByCustomerpersonaID = /* GraphQL */ `
  query ConceptsByCustomerpersonaID(
    $customerpersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptsByCustomerpersonaID(
      customerpersonaID: $customerpersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        projectbriefID
        likeStatus
        userFeedback
        image
        video
        title
        customerpersonaID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProjectBrief = /* GraphQL */ `
  query GetProjectBrief($id: ID!) {
    getProjectBrief(id: $id) {
      id
      owner
      title
      objective
      audience
      type
      method
      questions {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      concepts {
        nextToken
        __typename
      }
      conceptQuestions {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      respondentThreads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjectBriefs = /* GraphQL */ `
  query ListProjectBriefs(
    $filter: ModelProjectBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectBriefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        objective
        audience
        type
        method
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLLMAssistant = /* GraphQL */ `
  query GetLLMAssistant($id: ID!) {
    getLLMAssistant(id: $id) {
      id
      assistantID
      owner
      fileID
      name
      panelistID
      customerPersonaID
      threads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLLMAssistants = /* GraphQL */ `
  query ListLLMAssistants(
    $filter: ModelLLMAssistantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLLMAssistants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assistantID
        owner
        fileID
        name
        panelistID
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lLMAssistantsByPanelistID = /* GraphQL */ `
  query LLMAssistantsByPanelistID(
    $panelistID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLLMAssistantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lLMAssistantsByPanelistID(
      panelistID: $panelistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assistantID
        owner
        fileID
        name
        panelistID
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lLMAssistantsByCustomerPersonaID = /* GraphQL */ `
  query LLMAssistantsByCustomerPersonaID(
    $customerPersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLLMAssistantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lLMAssistantsByCustomerPersonaID(
      customerPersonaID: $customerPersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assistantID
        owner
        fileID
        name
        panelistID
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThread = /* GraphQL */ `
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      owner
      threadID
      messages {
        nextToken
        __typename
      }
      assistantID
      projectbriefID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThreads = /* GraphQL */ `
  query ListThreads(
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        threadID
        assistantID
        projectbriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const threadsByAssistantID = /* GraphQL */ `
  query ThreadsByAssistantID(
    $assistantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadsByAssistantID(
      assistantID: $assistantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        threadID
        assistantID
        projectbriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const threadsByProjectbriefID = /* GraphQL */ `
  query ThreadsByProjectbriefID(
    $projectbriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadsByProjectbriefID(
      projectbriefID: $projectbriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        threadID
        assistantID
        projectbriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      owner
      messageID
      role
      message
      LLMCreatedAt
      fileID
      threadID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        messageID
        role
        message
        LLMCreatedAt
        fileID
        threadID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByThreadID = /* GraphQL */ `
  query MessagesByThreadID(
    $threadID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByThreadID(
      threadID: $threadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        messageID
        role
        message
        LLMCreatedAt
        fileID
        threadID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPanelist = /* GraphQL */ `
  query GetPanelist($id: ID!) {
    getPanelist(id: $id) {
      id
      panelID
      owner
      name
      first_name
      last_name
      gender
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      children_in_household
      metro
      state
      country
      details
      history
      memory
      city
      region
      brandInteraction
      backstory
      summary
      psychographic_details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      age_range
      household_type
      household_size
      asset_range
      asset_types
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      assistants {
        nextToken
        __typename
      }
      personaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPanelists = /* GraphQL */ `
  query ListPanelists(
    $filter: ModelPanelistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanelists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        panelID
        owner
        name
        first_name
        last_name
        gender
        race
        education
        occupation
        marital_status
        age
        income
        net_worth
        job_title
        industry
        company_size
        seniority
        role
        children
        children_in_household
        metro
        state
        country
        details
        history
        memory
        city
        region
        brandInteraction
        backstory
        summary
        psychographic_details
        attitudes
        interests
        lifestyle
        personality
        social
        values
        age_range
        household_type
        household_size
        asset_range
        asset_types
        zipcode
        employment_status
        income_range
        networth_range
        interview_file
        personaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const panelistsByPanelID = /* GraphQL */ `
  query PanelistsByPanelID(
    $panelID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPanelistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    panelistsByPanelID(
      panelID: $panelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        panelID
        owner
        name
        first_name
        last_name
        gender
        race
        education
        occupation
        marital_status
        age
        income
        net_worth
        job_title
        industry
        company_size
        seniority
        role
        children
        children_in_household
        metro
        state
        country
        details
        history
        memory
        city
        region
        brandInteraction
        backstory
        summary
        psychographic_details
        attitudes
        interests
        lifestyle
        personality
        social
        values
        age_range
        household_type
        household_size
        asset_range
        asset_types
        zipcode
        employment_status
        income_range
        networth_range
        interview_file
        personaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const panelistsByPersonaID = /* GraphQL */ `
  query PanelistsByPersonaID(
    $personaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPanelistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    panelistsByPersonaID(
      personaID: $personaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        panelID
        owner
        name
        first_name
        last_name
        gender
        race
        education
        occupation
        marital_status
        age
        income
        net_worth
        job_title
        industry
        company_size
        seniority
        role
        children
        children_in_household
        metro
        state
        country
        details
        history
        memory
        city
        region
        brandInteraction
        backstory
        summary
        psychographic_details
        attitudes
        interests
        lifestyle
        personality
        social
        values
        age_range
        household_type
        household_size
        asset_range
        asset_types
        zipcode
        employment_status
        income_range
        networth_range
        interview_file
        personaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      owner
      sourceID
      gender
      name
      first_name
      last_name
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      metro
      state
      country
      details
      history
      memory
      city
      brandInteraction
      region
      age_range
      household_type
      household_size
      asset_range
      asset_types
      attitudes
      values
      lifestyle
      personality
      social
      interests
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        sourceID
        gender
        name
        first_name
        last_name
        race
        education
        occupation
        marital_status
        age
        income
        net_worth
        job_title
        industry
        company_size
        seniority
        role
        children
        metro
        state
        country
        details
        history
        memory
        city
        brandInteraction
        region
        age_range
        household_type
        household_size
        asset_range
        asset_types
        attitudes
        values
        lifestyle
        personality
        social
        interests
        zipcode
        employment_status
        income_range
        networth_range
        interview_file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profilesBySourceID = /* GraphQL */ `
  query ProfilesBySourceID(
    $sourceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesBySourceID(
      sourceID: $sourceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        sourceID
        gender
        name
        first_name
        last_name
        race
        education
        occupation
        marital_status
        age
        income
        net_worth
        job_title
        industry
        company_size
        seniority
        role
        children
        metro
        state
        country
        details
        history
        memory
        city
        brandInteraction
        region
        age_range
        household_type
        household_size
        asset_range
        asset_types
        attitudes
        values
        lifestyle
        personality
        social
        interests
        zipcode
        employment_status
        income_range
        networth_range
        interview_file
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSegments = /* GraphQL */ `
  query GetSegments($id: ID!) {
    getSegments(id: $id) {
      id
      name
      description
      owner
      customerPersonas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSegments = /* GraphQL */ `
  query ListSegments(
    $filter: ModelSegmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSources = /* GraphQL */ `
  query GetSources($id: ID!) {
    getSources(id: $id) {
      id
      name
      description
      owner
      filename
      customerProfiles {
        nextToken
        __typename
      }
      panels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSources = /* GraphQL */ `
  query ListSources(
    $filter: ModelSourcesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        filename
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPanel = /* GraphQL */ `
  query GetPanel($id: ID!) {
    getPanel(id: $id) {
      id
      name
      description
      owner
      sourceID
      panelists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPanels = /* GraphQL */ `
  query ListPanels(
    $filter: ModelPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPanels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        sourceID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const panelsBySourceID = /* GraphQL */ `
  query PanelsBySourceID(
    $sourceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPanelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    panelsBySourceID(
      sourceID: $sourceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        owner
        sourceID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerPersona = /* GraphQL */ `
  query GetCustomerPersona($id: ID!) {
    getCustomerPersona(id: $id) {
      id
      name
      persona_type
      personaID
      owner
      segmentID
      gender
      age
      education
      occupation
      marital_status
      income
      netWorth
      job_title
      industry
      company_size
      seniority
      role
      country
      city
      state
      region
      children
      race
      details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      userFeedback
      preference
      customerConversations {
        nextToken
        __typename
      }
      customerSurveyBriefs {
        nextToken
        __typename
      }
      customerInterviewBriefs {
        nextToken
        __typename
      }
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      ConceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      panelists {
        nextToken
        __typename
      }
      assistants {
        nextToken
        __typename
      }
      summary
      history
      memory
      Concepts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerPersonas = /* GraphQL */ `
  query ListCustomerPersonas(
    $filter: ModelCustomerPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerPersonas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        persona_type
        personaID
        owner
        segmentID
        gender
        age
        education
        occupation
        marital_status
        income
        netWorth
        job_title
        industry
        company_size
        seniority
        role
        country
        city
        state
        region
        children
        race
        details
        attitudes
        interests
        lifestyle
        personality
        social
        values
        userFeedback
        preference
        summary
        history
        memory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerPersonasBySegmentID = /* GraphQL */ `
  query CustomerPersonasBySegmentID(
    $segmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerPersonasBySegmentID(
      segmentID: $segmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        persona_type
        personaID
        owner
        segmentID
        gender
        age
        education
        occupation
        marital_status
        income
        netWorth
        job_title
        industry
        company_size
        seniority
        role
        country
        city
        state
        region
        children
        race
        details
        attitudes
        interests
        lifestyle
        personality
        social
        values
        userFeedback
        preference
        summary
        history
        memory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQualProjectBrief = /* GraphQL */ `
  query GetQualProjectBrief($id: ID!) {
    getQualProjectBrief(id: $id) {
      id
      owner
      title
      objective
      questions
      audience
      type
      method
      qualProjectResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQualProjectBriefs = /* GraphQL */ `
  query ListQualProjectBriefs(
    $filter: ModelQualProjectBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQualProjectBriefs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        objective
        questions
        audience
        type
        method
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQueAnswer = /* GraphQL */ `
  query GetQueAnswer($id: ID!) {
    getQueAnswer(id: $id) {
      id
      question
      answer
      personaID
      panelistID
      qualProjectResultsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQueAnswers = /* GraphQL */ `
  query ListQueAnswers(
    $filter: ModelQueAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        personaID
        panelistID
        qualProjectResultsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queAnswersByPersonaID = /* GraphQL */ `
  query QueAnswersByPersonaID(
    $personaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQueAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queAnswersByPersonaID(
      personaID: $personaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        answer
        personaID
        panelistID
        qualProjectResultsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queAnswersByPanelistID = /* GraphQL */ `
  query QueAnswersByPanelistID(
    $panelistID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQueAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queAnswersByPanelistID(
      panelistID: $panelistID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        answer
        personaID
        panelistID
        qualProjectResultsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queAnswersByQualProjectResultsID = /* GraphQL */ `
  query QueAnswersByQualProjectResultsID(
    $qualProjectResultsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQueAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queAnswersByQualProjectResultsID(
      qualProjectResultsID: $qualProjectResultsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        answer
        personaID
        panelistID
        qualProjectResultsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQualProjectResults = /* GraphQL */ `
  query GetQualProjectResults($id: ID!) {
    getQualProjectResults(id: $id) {
      id
      owner
      audience
      type
      method
      qualProjectBriefID
      qualProjectAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQualProjectResults = /* GraphQL */ `
  query ListQualProjectResults(
    $filter: ModelQualProjectResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQualProjectResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        audience
        type
        method
        qualProjectBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const qualProjectResultsByQualProjectBriefID = /* GraphQL */ `
  query QualProjectResultsByQualProjectBriefID(
    $qualProjectBriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQualProjectResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    qualProjectResultsByQualProjectBriefID(
      qualProjectBriefID: $qualProjectBriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        audience
        type
        method
        qualProjectBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerConversation = /* GraphQL */ `
  query GetCustomerConversation($id: ID!) {
    getCustomerConversation(id: $id) {
      id
      owner
      messages {
        nextToken
        __typename
      }
      title
      customerPersonaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerConversations = /* GraphQL */ `
  query ListCustomerConversations(
    $filter: ModelCustomerConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerConversationsByCustomerPersonaID = /* GraphQL */ `
  query CustomerConversationsByCustomerPersonaID(
    $customerPersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerConversationsByCustomerPersonaID(
      customerPersonaID: $customerPersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerMessage = /* GraphQL */ `
  query GetCustomerMessage($id: ID!) {
    getCustomerMessage(id: $id) {
      id
      owner
      message
      agent
      preference
      copied
      sources {
        url
        title
        text
        isAdded
        __typename
      }
      customerConversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerMessages = /* GraphQL */ `
  query ListCustomerMessages(
    $filter: ModelCustomerMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        agent
        preference
        copied
        customerConversationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerMessagesByCustomerConversationID = /* GraphQL */ `
  query CustomerMessagesByCustomerConversationID(
    $customerConversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerMessagesByCustomerConversationID(
      customerConversationID: $customerConversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        message
        agent
        preference
        copied
        customerConversationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerSurveyBrief = /* GraphQL */ `
  query GetCustomerSurveyBrief($id: ID!) {
    getCustomerSurveyBrief(id: $id) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        options
        __typename
      }
      questionText
      customerPersonaID
      customerSurveyResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerSurveyBriefs = /* GraphQL */ `
  query ListCustomerSurveyBriefs(
    $filter: ModelCustomerSurveyBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSurveyBriefs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        objective
        owner
        numberOfQuestions
        questionText
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerSurveyBriefsByCustomerPersonaID = /* GraphQL */ `
  query CustomerSurveyBriefsByCustomerPersonaID(
    $customerPersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSurveyBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSurveyBriefsByCustomerPersonaID(
      customerPersonaID: $customerPersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        objective
        owner
        numberOfQuestions
        questionText
        customerPersonaID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerSurveyResults = /* GraphQL */ `
  query GetCustomerSurveyResults($id: ID!) {
    getCustomerSurveyResults(id: $id) {
      id
      owner
      customerSurveyBriefID
      customerSurveyAnswers {
        id
        question
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerSurveyResults = /* GraphQL */ `
  query ListCustomerSurveyResults(
    $filter: ModelCustomerSurveyResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSurveyResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerSurveyBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerSurveyResultsByCustomerSurveyBriefID = /* GraphQL */ `
  query CustomerSurveyResultsByCustomerSurveyBriefID(
    $customerSurveyBriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerSurveyResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerSurveyResultsByCustomerSurveyBriefID(
      customerSurveyBriefID: $customerSurveyBriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerSurveyBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerInterviewBrief = /* GraphQL */ `
  query GetCustomerInterviewBrief($id: ID!) {
    getCustomerInterviewBrief(id: $id) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        __typename
      }
      questionText
      customerPersonaID
      customerInterviewResults {
        nextToken
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerInterviewBriefs = /* GraphQL */ `
  query ListCustomerInterviewBriefs(
    $filter: ModelCustomerInterviewBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerInterviewBriefs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        objective
        owner
        numberOfQuestions
        questionText
        customerPersonaID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerInterviewBriefsByCustomerPersonaID = /* GraphQL */ `
  query CustomerInterviewBriefsByCustomerPersonaID(
    $customerPersonaID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerInterviewBriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerInterviewBriefsByCustomerPersonaID(
      customerPersonaID: $customerPersonaID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        objective
        owner
        numberOfQuestions
        questionText
        customerPersonaID
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerInterviewResults = /* GraphQL */ `
  query GetCustomerInterviewResults($id: ID!) {
    getCustomerInterviewResults(id: $id) {
      id
      owner
      customerInterviewBriefID
      customerInterviewAnswers {
        id
        question
        answer
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerInterviewResults = /* GraphQL */ `
  query ListCustomerInterviewResults(
    $filter: ModelCustomerInterviewResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerInterviewResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerInterviewBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customerInterviewResultsByCustomerInterviewBriefID = /* GraphQL */ `
  query CustomerInterviewResultsByCustomerInterviewBriefID(
    $customerInterviewBriefID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerInterviewResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerInterviewResultsByCustomerInterviewBriefID(
      customerInterviewBriefID: $customerInterviewBriefID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        customerInterviewBriefID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
