import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import GetUser from "../users/GetUser";

const GetConcepts = async () => {

    const userInfo = await GetUser();
    console.log("Username: ", userInfo.username);

    let concepts = [];

    try {
        const apiData = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.listConcepts
        });

        console.log("API Data: ", apiData);
        concepts = apiData.data.listConcepts.items;
        console.log(concepts);
    } catch (error) {
        console.error("GetConcepts error: ", error);
        return null;
    }

    return concepts;
}

export default GetConcepts;