import { useEffect, useState } from 'react';
import { Auth, API, Storage } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { FormatColorReset } from '@mui/icons-material';
import Loading from '../../utils/Loading';
import { DataGrid } from '@mui/x-data-grid';
import {v4 as uuidv4} from 'uuid';
import PanelBrief from './PanelBrief';
import PanelDashboard from './PanelDashboard';
import Profiles from './Profiles';
import Papa, { parse } from 'papaparse';
import JSZip, { remove } from 'jszip';
import SimpleLLMCall from '../../utils/llmcall/SimpleLLMCall';
import SimpleLLMCallJSON from '../../utils/llmcall/SimpleLLMCallJSON';
import GetPanels from '../../utils/panels/GetPanels';
import GetSources from '../../utils/panels/GetSources';
import SaveSources from '../../utils/panels/SaveSources';
import SavePanels from '../../utils/panels/SavePanels';
import SaveProfiles from '../../utils/panels/SaveProfiles';
import SavePanelists from '../../utils/panels/SavePanelists';
import GetProfiles from '../../utils/panels/GetProfiles';
import SaveLLMAssistantDB from '../../utils/llmcall/SaveLLMAssistantDB';
import { CreateAssistantLLM } from '../../utils/llmcall/CreateAssistantLLM';
import { CreateThreadLLM } from '../../utils/llmcall/CreateThreadLLM';
import SaveLLMThreadDB from '../../utils/llmcall/SaveLLMThreadDB';
import { CreateMessageLLM } from '../../utils/llmcall/CreateMessageLLM';
import SaveLLMMessageDB from '../../utils/llmcall/SaveLLMMessageDB';

// project workflow state
export const WorkflowStatus = {
    brief:"brief",
    importSource: "importing",
    importCompleted: "importCompleted",
    createPanel: "createPanel",
    panelCreated: "panelCreated",
}

// openai models
const model = "gpt-3.5-turbo";
const model2 = "gpt-4";

const NewPanelWorkflow = (props) => {   

    console.log("NewPanelWorkflow starts")
    // workflow status
    const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.brief);
    const [personaList, setPersonaList] = useState([]);
    const [sources, setSources] = useState([]);
    const [user, setUser] = useState(null); // user is null if not logged in

    const navigate = useNavigate();

    // useEffect
    useEffect(() => {

        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
                console.log(amplifyUser)
            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }

        async function getSources() {
            const sources = await GetSources();
            if (sources) {
                setSources(sources);
            }
        }

        fetchUser();
        getSources();

    }, [workflowStatus]);

    async function createProfiles (acceptedFiles) {

        setWorkflowStatus("importing");

        console.log("Workflow status: ", workflowStatus);

        const parseFile = async (file) => {
            const results = [];
            return new Promise((resolve, reject) => {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    worker: true,
                    step: function(result) {
                        results.push(result.data);
                    },
                    complete: function() {
                        resolve(results);
                    },
                    error: reject
                });
            });
        };

        console.log(acceptedFiles);
        let profiles = [];
    
        if (acceptedFiles.length > 0) { 

            if (acceptedFiles[0].type === 'application/zip') {
                console.log("READING ZIPPED FILE")
                const jszip = new JSZip();
                const otherFiles = [];                

                const zip = await jszip.loadAsync(acceptedFiles[0])
                const files = Object.values(zip.files)
                await Promise.all(files.map(async function (zipEntry) {
                    const relativePath = zipEntry.name;
                    const extension = relativePath.split('.').pop();
                    if (!['pdf', 'csv', 'xls', 'xlsx'].includes(extension)) {
                        console.warn('Invalid file type: ' + relativePath);
                        return;
                    }

                    if (relativePath.startsWith('/') || relativePath.includes('../') || relativePath.includes(':')) {
                        console.warn('Invalid file path: ' + relativePath);
                        return;  // Skip this file
                    }

                    if (extension === 'csv') {
                        const text = await zipEntry.async('text');
                        const file = new File([text], zipEntry.name, { type: 'text/csv' });
                        profiles = await parseFile(file);

                    } else {
                        otherFiles.push({name: zipEntry.name, data:zipEntry.async('blob')});
                    }
                }));                

                console.log("Profiles: ");
                console.log(profiles);
                console.log("Other Files: ");
                console.log(otherFiles);
                

                for (let i=0;i<profiles.length;++i) {
                    const profile = profiles[i];
                    console.log(profile);
                    if (profile.Interview_File) {
                        const interviewFile = profile.Interview_File;
                        // find the file from otherFiles with interview file name
                        const fileObj = otherFiles.find(file => file.name === interviewFile);
                        // save to S3 only if the file is found
                        if (fileObj) {

                            const file = await fileObj.data;
                            console.log(interviewFile);

                            // Extract the original filename without the extension
                            const origFilenameWithoutExt = interviewFile.substring(0, interviewFile.lastIndexOf('.'));

                            // Generate a unique filename
                            const uniqueFilename = `${origFilenameWithoutExt}_${uuidv4()}.pdf`;

                            await Storage.put(uniqueFilename, file, {
                                contentType: 'application/pdf',
                            });

                            // Update profile.Interview_File with the new name
                            profile.Interview_File = uniqueFilename;
                        }
                    }
                }
            }
            else {
                console.log("READING CSV FILE")
                try {
                    profiles = await parseFile(acceptedFiles[0]);
                } catch (error) {
                    console.error('Error parsing file:', error);
                }
            }
               //parseFile(acceptedFiles[0]).then(async(profiles) => {
            console.log("read the profiles");
            console.log(profiles);
            const user_profile = await Auth.currentAuthenticatedUser();
            const owner = user_profile.attributes.email;
                    
                    // save Source to db
                    // create source object
            const nameDes = acceptedFiles[0].path.split(".")[0];
            const sourceData = {
                        name: nameDes,
                        description: nameDes,
                        filename: acceptedFiles[0].path,
                        owner: owner,
            }
            console.log(sourceData);
                    
            // save to db
            const sourceID = await SaveSources(sourceData,"create");
    
                    
    
                    // move this functionality to utis later for refactoring
                    const profileData = profiles.map((profile) => {
                        if (profile) {
                            return {
                                sourceID: sourceID,
                                owner: owner,
                                age: parseInt(profile.Age, 10), // Ensure age is an integer
                                gender: profile.Gender,
                                race: profile.Race,
                                children: parseInt(profile.Children, 10), // Ensure children is an integer
                                education: profile.Education,
                                occupation: profile.Occupation,
                                marital_status: profile.Marital_Status,
                                net_worth: parseInt(profile.Net_Worth, 10), // Ensure net_worth is an integer
                                income: parseInt(profile.Income, 10), // Ensure income is an integer
                                city: profile.City,
                                metro: profile.Metro,
                                region: profile.Region,
                                state: profile.State,
                                country: profile.Country,
                                age_range: profile.Age_Range,
                                household_size: profile.Household_Size,
                                household_type: profile.Household_Type,
                                zipcode: parseInt(profile.Zipcode, 10), // Ensure zipcode is an integer
                                employment_status: profile.Employment_Status,
                                asset_range: profile.Asset_Range,
                                asset_types: profile.Asset_Types,
                                income_range: profile.Income_Range,
                                networth_range: profile.Networth_Range,
                                attitudes: profile.Attitudes,
                                values: profile.Values,
                                interests: profile.Interests,
                                lifestyle: profile.Lifestyle,
                                personality: profile.Personality,
                                social: profile.Social,
                                interview_file: profile.Interview_File,
                                first_name: profile.First_Name,
                                last_name: profile.Last_Name,
                                industry: profile.Industry,
                            }
                        }
                    })
    
                    console.log(profileData);
    
                    // Define the batch size
                    const batchSize = 100;
    
                    // Split the profiles into batches
                    const batches = [];
                    for (let i = 0; i < profileData.length; i += batchSize) {
                        batches.push(profileData.slice(i, i + batchSize));
                    }
    
                    // Save each batch separately
                    for (const batch of batches) {
                        const updatedProfiles = [];
                        for (const profile of batch) {
                            try {
                                const id = await SaveProfiles(profile, "create");
                                profile.id = id;
                                updatedProfiles.push(profile);
                            } catch (error) {
                                console.error('Error saving profile:', error);
                            }
                        }
                        console.log(updatedProfiles);
                    }
    
                //})
                //.catch(error => {
                //    console.error('Error parsing file:', error);
                //});
                      
            console.log("Profiles imported successfully!");

            //getSources();
            
            setWorkflowStatus("importCompleted");

            console.log("Workflow status: ", workflowStatus);
        }
        
    }

    

    // remove personal data
    const removePII = (panelist) => {
        const panelistCopy = {...panelist};
        // delete all null values
        for (const key in panelistCopy) {
            if (panelistCopy[key] === null ) {
                delete panelistCopy[key];
            }
        }
        delete panelistCopy.panelID;
        delete panelistCopy.id;        
        delete panelistCopy.owner;
        return panelistCopy;
    }

    async function createPanel(data) {

        setWorkflowStatus(WorkflowStatus.createPanel);
        console.log("Creating Panel: ", data);
        
        const user_profile = await Auth.currentAuthenticatedUser();
        const owner = user_profile.attributes.email;
        const sourceID = data.dataSource.id;

        // create panel object
        const panelData = {
            name: data.name,
            description: data.description,
            owner: owner,
            sourceID: sourceID,
        }

        // save to db
        const panelID = await SavePanels(panelData, "create");
        console.log("Panel ID created: " + panelID);

        // create a new assistant for this panel
        /*let dataToLLM = {}
        dataToLLM.model = model;
        dataToLLM.temperature = 0.5;
        dataToLLM.mode = "assistant";
        dataToLLM.llm_data = {
            instructions: "You are an assistant for a market research panel. There will be one thread per panelist and additional threads for analysis of the panelists' data",
            name: "Market-Research-Panel-" + data.name,
            assistantMode: "createAssistant",
        }
        const assistantData =  await CreateAssistantLLM(dataToLLM);
        

        // save the assistant to the DB
        const assistantDBData = {
            assistantID: assistantData.assistantID,
            panelID: panelID,
            owner: owner,
            name: "Market-Research-Panel-" + data.name,
        }
        if (assistantData.fileID) {
            assistantDBData.fileID = assistantData.fileID;
        }

        const assistantDBID = await SaveLLMAssistantDB(assistantDBData).catch(error => {
            console.error('Error saving LLM Assistant:', error);
            console.error('Error details:', error.errors);
        });
        console.log("Assistant ID: ", assistantDBID);*/

        const panelists = data.profiles.map((profile) => {
           

            return {
                panelID: panelID, 
                first_name: profile.first_name ? profile.first_name : undefined,
                last_name: profile.last_name? profile.last_name : undefined,
                age: parseInt(profile.age, 10), // Ensure age is an integer
                gender: profile.gender,
                race: profile.race,
                children: parseInt(profile.children, 10), // Ensure children is an integer
                education: profile.education,
                occupation: profile.occupation,
                marital_status: profile.marital_status,
                net_worth: parseInt(profile.net_worth, 10), // Ensure net_worth is an integer
                income: parseInt(profile.income, 10), // Ensure income is an integer
                city: profile.city,
                metro: profile.metro,
                region: profile.region,
                state: profile.state,
                country: profile.country,
                age_range: profile.age_range,
                household_size: profile.household_size,
                household_type: profile.household_type,
                zipcode: parseInt(profile.zipcode, 10), // Ensure zipcode is an integer
                employment_status: profile.employment_status,
                asset_range: profile.asset_range,
                asset_types: profile.asset_types,
                income_range: profile.income_range,
                attitudes: profile.attitudes,
                values: profile.values,
                interests: profile.interests,
                lifestyle: profile.lifestyle,
                personality: profile.personality,
                social: profile.social,
                interview_file: profile.interview_file ? profile.interview_file : undefined,
            }   
        })

        console.log("Panelists before saving to DB: ", panelists);

        let savedPanelists = [];
/*        // create backstories for the panelists
        const panelistDataString = JSON.stringify(panelists);
        console.log(panelistDataString);
        const system_prompt = `Come up with a backstory of 1-2 paragraphs and psychographic details for each of these panelists based on their demographics. 
        The input data is a JSON object containing the panelists' demographics.
        return the JSON object with the panelists' in following format:
        { {"panelID": panelID from input, "backstory": unique backstory in 2-3 sentences, 
        "values": values and beliefs for the panelist, "attitudes": attitudes and behaviours for panelist, 
        "social": social and cultural factors for panelist, "personality": personality traits for panelist,
        "interests": hobbies and interests for the panelist, "lifestyle": lifestyle for the panelist},
        {"panelID": panelID from input,...},...} }
        `;
        const dataToLLM = {
            model: model,
            question: "Here are the panelists: " + panelistDataString,
            system_prompt: system_prompt,
            history: "",
            sources: [],
            temperature: 0.3,
        }
        let response = await SimpleLLMCall(dataToLLM);
        
        
        // get the panelists backstories and psycographics and add it to the panelist object before saving to the DB
        // 1. convert response to JSON
        let panelistBackstories = JSON.parse(response);
        console.log(panelistBackstories);
        */
        // 2. loop through the JSON and add the backstories to the panelists

            for (const panelist of panelists) {
                panelist.owner = owner;

                if (data.backstory.value === 'Yes') {

                    // create a backstory and psychographics details for this panelist
                    const system_prompt = 
                    `Create a backstory and psychographic details for each profile. 
                    The input data is a JSON object containing details of an individual's profile. 
                    Return single JSON object with ONLY specified fields in following format: 
                    {"name": first and last name reflecting the demographics of the profile, "backstory": unique backstory, "values": values and beliefs for the profile, "attitudes": attitudes and behaviors for the profile, "social": social and cultural factors for the profile, "personality": personality traits for the profile, "interests": hobbies and interests for the profile, "lifestyle": lifestyle for the profile}.`;

                    const dataToLLM = {
                            model: model,
                            question: "Panelist details: " + JSON.stringify(removePII(panelist)),
                            system_prompt: system_prompt,
                            history: "",
                            sources: [],
                            temperature: 0.7,
                            response_format: { type: "json_object" },
                    };

                    let panelist_backstory = await SimpleLLMCallJSON(dataToLLM);
                    console.log(panelist_backstory)

                    //const panelist_backstory = JSON.parse(response);
                    panelist.name = panelist_backstory.name;
                    panelist.backstory = panelist_backstory.backstory;
                    panelist.values = panelist_backstory.values;
                    panelist.attitudes = panelist_backstory.attitudes;
                    panelist.social = panelist_backstory.social;
                    panelist.personality = panelist_backstory.personality;
                    panelist.interests = panelist_backstory.interests;
                    panelist.lifestyle = panelist_backstory.lifestyle;

                } else if (!panelist.first_name || !panelist.last_name) {
                    // create just the name for this panelist
                    const system_prompt = 
                    `Create a name for each profile.
                    The input data is a JSON object containing details of an individual's profile. 
                    Return single JSON object with ONLY specified fields in following format: 
                    {"panelID": panelID from input, "name": first and last name reflecting the demographics of the profile}.`;

                    const dataToLLM = {
                            model: model,
                            question: "Panelist details: " + JSON.stringify(removePII(panelist)),
                            system_prompt: system_prompt,
                            history: "",
                            sources: [],
                            temperature: 0.7,
                            response_format: { type: "json_object" },
                    };

                    let panelistDataFromLLM = await SimpleLLMCallJSON(dataToLLM);
                    console.log(panelistDataFromLLM)
                    panelist.name = panelistDataFromLLM.name;
                } else {
                    // create the name from the first and last name   
                    
                    panelist.name = panelist.first_name && panelist.last_name ? 
                        panelist.first_name + " " + panelist.last_name : 
                            panelist.first_name ? panelist.first_name : panelist.last_name;
                }
            
                console.log(panelist);
                console.log("Panelist without PII: ");
                console.log(removePII(panelist));

                // 3. save the panelists to the DB
                try {
                    let id = await SavePanelists(panelist, "create");
                    let panelistCopy = { ...panelist, id: id };
                    savedPanelists.push(panelistCopy);
                } catch(error) {
                    console.error('Error saving profile:', error);
                    console.error('Error details:', error.errors);
                }
            }

            // async call to sall savedPanelists to create LLM assistant/thread/messages
            let LLMData = {};
            LLMData.model = model;
            LLMData.temperature = 0.5;
            LLMData.mode = "assistant";
            let llm_data ={}
            let delay =2000;
            let promises = savedPanelists.map( async(panelist, index) => {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            if (panelist.interview_file) {
                                const url = await Storage.get(panelist.interview_file);
                                const urlParts = new URL(url);
                                const key = decodeURIComponent(urlParts.pathname.substring(1));
                                console.log(key);
                                llm_data = {
                                    assistantMode: "createAssistant",
                                    name: data.name + "-" + panelist.name,
                                    instructions: "You are a panelist in a market research panel. Answer questions based on the interview profile provided and your profile" + JSON.stringify(removePII(panelist)),
                                    filename: key,
                                }
                            }
                            else {
                                llm_data = {
                                    assistantMode: "createAssistant",
                                    name: data.name + "-" + panelist.name,
                                    instructions: "You are a panelist in a market research panel. Answer questions based on your profile" + JSON.stringify(removePII(panelist)),
                                }
                            }
                            LLMData.llm_data = llm_data;
                            console.log("LLM Data: ", LLMData);
                            return CreateAssistantLLM(LLMData)
                                .then(response => {
                                   

                                    // create message
                                    //panelist.assistantID = response.assistantID;
                                    // save the assistant to the DB
                                    const assistantDBData = {
                                        assistantID: response.assistantID,
                                        panelistID: panelist.id,
                                        owner: owner,
                                        name: "asst-panelist-"+panelist.name,
                                    }
                                    if (response.fileID) {
                                        assistantDBData.fileID = response.fileID;
                                    }
                                    SaveLLMAssistantDB(assistantDBData)
                                        .then(async assistantDBid => {
                                            console.log("Assistant DB ID: ", assistantDBid);
                                            /*if (panelist.interview_file) {

                                                // create a thread for the panelist
                                                const threadDataLLM = {
                                                    assistantMode: "createThread",
                                                }
                                                LLMData.llm_data  = threadDataLLM;
                                                console.log("Thread Data: ", LLMData);
                                                let threadID = await CreateThreadLLM(LLMData);


                                                // create a message for the panelist
                                                let messageData = {
                                                    threadID: threadID,
                                                    assistantID: response.assistantID,
                                                    owner: owner,
                                                    message: "Summarise the profile for the panelist using interview transcript attached. Return in JSON format"+
                                                    '{"summary":<summary of profile>, "psychographic_details":<psychographic details of profile>}'
                                                }
                                                LLMData.llm_data = messageData;
                                                let messageResponse = await CreateMessageLLM(LLMData);
                                                let messageJSON = JSON.parse(messageResponse[0].message)
                                                panelist.summary = messageJSON.summary;
                                                panelist.psychographic_details = messageJSON.psychographic_details;

                                                // create second message for this panelist
                                                messageData = {
                                                    threadID: threadID,
                                                    assistantID: response.assistantID,
                                                    owner: owner,
                                                    message: "Thanks for that. Now provide a backstory for this panelist and detailed psychographic parameters like values etc using interview transcript attached. Return in JSON format"+
                                                    '{"backstory": backstory, "values": values and beliefs for the panelist, "attitudes": attitudes and behaviors for the panelist, "social": social and cultural factors for the panelist, "personality": personality traits for the panelist, "interests": hobbies and interests for the panelist, "lifestyle": lifestyle for the panelist}'
                                                }
                                                LLMData.llm_data = messageData;
                                                messageResponse = await CreateMessageLLM(LLMData);
                                                messageJSON = JSON.parse(messageResponse[0].message)
                                                panelist.backstory = messageJSON.backstory;
                                                panelist.psychographic_details = messageJSON.psychographic_details;
                                                panelist.values = messageJSON.values;
                                                panelist.attitudes = messageJSON.attitudes;
                                                panelist.social = messageJSON.social;
                                                panelist.personality = messageJSON.personality;
                                                panelist.interests = messageJSON.interests;
                                                panelist.lifestyle = messageJSON.lifestyle;


                                                // save the thread to the DB
                                                const threadDBData = {
                                                    threadID: threadID,
                                                    owner: owner,
                                                    assistantID: assistantDBid,
                                                }
                                                SaveLLMThreadDB(threadDBData)
                                                .then(threaddbid => {
                                                    return panelist;
                                                });
                                            }*/
                                            return panelist;                                       
                                        })
                                        .catch(error => {
                                            console.error('Error saving LLM Assistant:', error);
                                            console.error('Error details:', error.errors);
                                        });

                                     
                                });

                        }
                        catch (error) {
                            console.error("Error creating LLM assistant/thread/messages: ", error);
                        }
                    }, index*delay);
                })
                
            });

            Promise.all(promises)
                .then(updatedPanelists  => {
                    savedPanelists = updatedPanelists;
                })
                .catch(error => {
                    console.error("Error updating panelist: ", error);
                })
                
                
            console.log("Saved Panelists: ", savedPanelists);

            navigate(`/panels/${panelID}`);

    }

    return (
        <div className="flex flex-col w-full h-full bg-slate-50">
            <div className="flex flex-col w-full h-full bg-slate-50">
                {workflowStatus === WorkflowStatus.brief && <PanelBrief sources={sources} createPanel={createPanel} createProfiles={createProfiles}/>}
                {workflowStatus === "importing" && <div><h1>Importing data... please stand by</h1><Loading /></div>}
                {workflowStatus === "importCompleted" && <PanelBrief sources={sources} createPanel={createPanel} createProfiles={createProfiles}/>}
                {workflowStatus === WorkflowStatus.createPanel && <div><h1>Creating panel... please stand by</h1><Loading /></div>}
            </div>
        </div>
    );
}

export default NewPanelWorkflow;
