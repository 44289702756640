/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBrandPositioning = /* GraphQL */ `
  mutation CreateBrandPositioning(
    $input: CreateBrandPositioningInput!
    $condition: ModelBrandPositioningConditionInput
  ) {
    createBrandPositioning(input: $input, condition: $condition) {
      id
      positioning
      messaging
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBrandPositioning = /* GraphQL */ `
  mutation UpdateBrandPositioning(
    $input: UpdateBrandPositioningInput!
    $condition: ModelBrandPositioningConditionInput
  ) {
    updateBrandPositioning(input: $input, condition: $condition) {
      id
      positioning
      messaging
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBrandPositioning = /* GraphQL */ `
  mutation DeleteBrandPositioning(
    $input: DeleteBrandPositioningInput!
    $condition: ModelBrandPositioningConditionInput
  ) {
    deleteBrandPositioning(input: $input, condition: $condition) {
      id
      positioning
      messaging
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLLMJob = /* GraphQL */ `
  mutation CreateLLMJob(
    $input: CreateLLMJobInput!
    $condition: ModelLLMJobConditionInput
  ) {
    createLLMJob(input: $input, condition: $condition) {
      id
      status
      result
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLLMJob = /* GraphQL */ `
  mutation UpdateLLMJob(
    $input: UpdateLLMJobInput!
    $condition: ModelLLMJobConditionInput
  ) {
    updateLLMJob(input: $input, condition: $condition) {
      id
      status
      result
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLLMJob = /* GraphQL */ `
  mutation DeleteLLMJob(
    $input: DeleteLLMJobInput!
    $condition: ModelLLMJobConditionInput
  ) {
    deleteLLMJob(input: $input, condition: $condition) {
      id
      status
      result
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestions = /* GraphQL */ `
  mutation CreateQuestions(
    $input: CreateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    createQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      Answers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateQuestions = /* GraphQL */ `
  mutation UpdateQuestions(
    $input: UpdateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    updateQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      Answers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteQuestions = /* GraphQL */ `
  mutation DeleteQuestions(
    $input: DeleteQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    deleteQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      Answers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAnswers = /* GraphQL */ `
  mutation CreateAnswers(
    $input: CreateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    createAnswers(input: $input, condition: $condition) {
      id
      answer
      questionsID
      panelistID
      projectbriefID
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAnswers = /* GraphQL */ `
  mutation UpdateAnswers(
    $input: UpdateAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    updateAnswers(input: $input, condition: $condition) {
      id
      answer
      questionsID
      panelistID
      projectbriefID
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAnswers = /* GraphQL */ `
  mutation DeleteAnswers(
    $input: DeleteAnswersInput!
    $condition: ModelAnswersConditionInput
  ) {
    deleteAnswers(input: $input, condition: $condition) {
      id
      answer
      questionsID
      panelistID
      projectbriefID
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConceptAnswers = /* GraphQL */ `
  mutation CreateConceptAnswers(
    $input: CreateConceptAnswersInput!
    $condition: ModelConceptAnswersConditionInput
  ) {
    createConceptAnswers(input: $input, condition: $condition) {
      id
      answer
      conceptquestionsID
      panelistID
      projectbriefID
      customerpersonaID
      conceptsID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConceptAnswers = /* GraphQL */ `
  mutation UpdateConceptAnswers(
    $input: UpdateConceptAnswersInput!
    $condition: ModelConceptAnswersConditionInput
  ) {
    updateConceptAnswers(input: $input, condition: $condition) {
      id
      answer
      conceptquestionsID
      panelistID
      projectbriefID
      customerpersonaID
      conceptsID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConceptAnswers = /* GraphQL */ `
  mutation DeleteConceptAnswers(
    $input: DeleteConceptAnswersInput!
    $condition: ModelConceptAnswersConditionInput
  ) {
    deleteConceptAnswers(input: $input, condition: $condition) {
      id
      answer
      conceptquestionsID
      panelistID
      projectbriefID
      customerpersonaID
      conceptsID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConceptQuestions = /* GraphQL */ `
  mutation CreateConceptQuestions(
    $input: CreateConceptQuestionsInput!
    $condition: ModelConceptQuestionsConditionInput
  ) {
    createConceptQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      conceptAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConceptQuestions = /* GraphQL */ `
  mutation UpdateConceptQuestions(
    $input: UpdateConceptQuestionsInput!
    $condition: ModelConceptQuestionsConditionInput
  ) {
    updateConceptQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      conceptAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConceptQuestions = /* GraphQL */ `
  mutation DeleteConceptQuestions(
    $input: DeleteConceptQuestionsInput!
    $condition: ModelConceptQuestionsConditionInput
  ) {
    deleteConceptQuestions(input: $input, condition: $condition) {
      id
      method
      options
      question
      projectbriefID
      conceptAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConcepts = /* GraphQL */ `
  mutation CreateConcepts(
    $input: CreateConceptsInput!
    $condition: ModelConceptsConditionInput
  ) {
    createConcepts(input: $input, condition: $condition) {
      id
      description
      projectbriefID
      likeStatus
      userFeedback
      image
      video
      conceptAnswerss {
        nextToken
        __typename
      }
      title
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConcepts = /* GraphQL */ `
  mutation UpdateConcepts(
    $input: UpdateConceptsInput!
    $condition: ModelConceptsConditionInput
  ) {
    updateConcepts(input: $input, condition: $condition) {
      id
      description
      projectbriefID
      likeStatus
      userFeedback
      image
      video
      conceptAnswerss {
        nextToken
        __typename
      }
      title
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConcepts = /* GraphQL */ `
  mutation DeleteConcepts(
    $input: DeleteConceptsInput!
    $condition: ModelConceptsConditionInput
  ) {
    deleteConcepts(input: $input, condition: $condition) {
      id
      description
      projectbriefID
      likeStatus
      userFeedback
      image
      video
      conceptAnswerss {
        nextToken
        __typename
      }
      title
      customerpersonaID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProjectBrief = /* GraphQL */ `
  mutation CreateProjectBrief(
    $input: CreateProjectBriefInput!
    $condition: ModelProjectBriefConditionInput
  ) {
    createProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      audience
      type
      method
      questions {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      concepts {
        nextToken
        __typename
      }
      conceptQuestions {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      respondentThreads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProjectBrief = /* GraphQL */ `
  mutation UpdateProjectBrief(
    $input: UpdateProjectBriefInput!
    $condition: ModelProjectBriefConditionInput
  ) {
    updateProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      audience
      type
      method
      questions {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      concepts {
        nextToken
        __typename
      }
      conceptQuestions {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      respondentThreads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProjectBrief = /* GraphQL */ `
  mutation DeleteProjectBrief(
    $input: DeleteProjectBriefInput!
    $condition: ModelProjectBriefConditionInput
  ) {
    deleteProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      audience
      type
      method
      questions {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      concepts {
        nextToken
        __typename
      }
      conceptQuestions {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      respondentThreads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLLMAssistant = /* GraphQL */ `
  mutation CreateLLMAssistant(
    $input: CreateLLMAssistantInput!
    $condition: ModelLLMAssistantConditionInput
  ) {
    createLLMAssistant(input: $input, condition: $condition) {
      id
      assistantID
      owner
      fileID
      name
      panelistID
      customerPersonaID
      threads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLLMAssistant = /* GraphQL */ `
  mutation UpdateLLMAssistant(
    $input: UpdateLLMAssistantInput!
    $condition: ModelLLMAssistantConditionInput
  ) {
    updateLLMAssistant(input: $input, condition: $condition) {
      id
      assistantID
      owner
      fileID
      name
      panelistID
      customerPersonaID
      threads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLLMAssistant = /* GraphQL */ `
  mutation DeleteLLMAssistant(
    $input: DeleteLLMAssistantInput!
    $condition: ModelLLMAssistantConditionInput
  ) {
    deleteLLMAssistant(input: $input, condition: $condition) {
      id
      assistantID
      owner
      fileID
      name
      panelistID
      customerPersonaID
      threads {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThread = /* GraphQL */ `
  mutation CreateThread(
    $input: CreateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    createThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      messages {
        nextToken
        __typename
      }
      assistantID
      projectbriefID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThread = /* GraphQL */ `
  mutation UpdateThread(
    $input: UpdateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    updateThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      messages {
        nextToken
        __typename
      }
      assistantID
      projectbriefID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThread = /* GraphQL */ `
  mutation DeleteThread(
    $input: DeleteThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    deleteThread(input: $input, condition: $condition) {
      id
      owner
      threadID
      messages {
        nextToken
        __typename
      }
      assistantID
      projectbriefID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      owner
      messageID
      role
      message
      LLMCreatedAt
      fileID
      threadID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      owner
      messageID
      role
      message
      LLMCreatedAt
      fileID
      threadID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      owner
      messageID
      role
      message
      LLMCreatedAt
      fileID
      threadID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPanelist = /* GraphQL */ `
  mutation CreatePanelist(
    $input: CreatePanelistInput!
    $condition: ModelPanelistConditionInput
  ) {
    createPanelist(input: $input, condition: $condition) {
      id
      panelID
      owner
      name
      first_name
      last_name
      gender
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      children_in_household
      metro
      state
      country
      details
      history
      memory
      city
      region
      brandInteraction
      backstory
      summary
      psychographic_details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      age_range
      household_type
      household_size
      asset_range
      asset_types
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      assistants {
        nextToken
        __typename
      }
      personaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePanelist = /* GraphQL */ `
  mutation UpdatePanelist(
    $input: UpdatePanelistInput!
    $condition: ModelPanelistConditionInput
  ) {
    updatePanelist(input: $input, condition: $condition) {
      id
      panelID
      owner
      name
      first_name
      last_name
      gender
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      children_in_household
      metro
      state
      country
      details
      history
      memory
      city
      region
      brandInteraction
      backstory
      summary
      psychographic_details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      age_range
      household_type
      household_size
      asset_range
      asset_types
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      assistants {
        nextToken
        __typename
      }
      personaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePanelist = /* GraphQL */ `
  mutation DeletePanelist(
    $input: DeletePanelistInput!
    $condition: ModelPanelistConditionInput
  ) {
    deletePanelist(input: $input, condition: $condition) {
      id
      panelID
      owner
      name
      first_name
      last_name
      gender
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      children_in_household
      metro
      state
      country
      details
      history
      memory
      city
      region
      brandInteraction
      backstory
      summary
      psychographic_details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      conceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      age_range
      household_type
      household_size
      asset_range
      asset_types
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      assistants {
        nextToken
        __typename
      }
      personaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      owner
      sourceID
      gender
      name
      first_name
      last_name
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      metro
      state
      country
      details
      history
      memory
      city
      brandInteraction
      region
      age_range
      household_type
      household_size
      asset_range
      asset_types
      attitudes
      values
      lifestyle
      personality
      social
      interests
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      owner
      sourceID
      gender
      name
      first_name
      last_name
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      metro
      state
      country
      details
      history
      memory
      city
      brandInteraction
      region
      age_range
      household_type
      household_size
      asset_range
      asset_types
      attitudes
      values
      lifestyle
      personality
      social
      interests
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      owner
      sourceID
      gender
      name
      first_name
      last_name
      race
      education
      occupation
      marital_status
      age
      income
      net_worth
      job_title
      industry
      company_size
      seniority
      role
      children
      metro
      state
      country
      details
      history
      memory
      city
      brandInteraction
      region
      age_range
      household_type
      household_size
      asset_range
      asset_types
      attitudes
      values
      lifestyle
      personality
      social
      interests
      zipcode
      employment_status
      income_range
      networth_range
      interview_file
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSegments = /* GraphQL */ `
  mutation CreateSegments(
    $input: CreateSegmentsInput!
    $condition: ModelSegmentsConditionInput
  ) {
    createSegments(input: $input, condition: $condition) {
      id
      name
      description
      owner
      customerPersonas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSegments = /* GraphQL */ `
  mutation UpdateSegments(
    $input: UpdateSegmentsInput!
    $condition: ModelSegmentsConditionInput
  ) {
    updateSegments(input: $input, condition: $condition) {
      id
      name
      description
      owner
      customerPersonas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSegments = /* GraphQL */ `
  mutation DeleteSegments(
    $input: DeleteSegmentsInput!
    $condition: ModelSegmentsConditionInput
  ) {
    deleteSegments(input: $input, condition: $condition) {
      id
      name
      description
      owner
      customerPersonas {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSources = /* GraphQL */ `
  mutation CreateSources(
    $input: CreateSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    createSources(input: $input, condition: $condition) {
      id
      name
      description
      owner
      filename
      customerProfiles {
        nextToken
        __typename
      }
      panels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSources = /* GraphQL */ `
  mutation UpdateSources(
    $input: UpdateSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    updateSources(input: $input, condition: $condition) {
      id
      name
      description
      owner
      filename
      customerProfiles {
        nextToken
        __typename
      }
      panels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSources = /* GraphQL */ `
  mutation DeleteSources(
    $input: DeleteSourcesInput!
    $condition: ModelSourcesConditionInput
  ) {
    deleteSources(input: $input, condition: $condition) {
      id
      name
      description
      owner
      filename
      customerProfiles {
        nextToken
        __typename
      }
      panels {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPanel = /* GraphQL */ `
  mutation CreatePanel(
    $input: CreatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    createPanel(input: $input, condition: $condition) {
      id
      name
      description
      owner
      sourceID
      panelists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePanel = /* GraphQL */ `
  mutation UpdatePanel(
    $input: UpdatePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    updatePanel(input: $input, condition: $condition) {
      id
      name
      description
      owner
      sourceID
      panelists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePanel = /* GraphQL */ `
  mutation DeletePanel(
    $input: DeletePanelInput!
    $condition: ModelPanelConditionInput
  ) {
    deletePanel(input: $input, condition: $condition) {
      id
      name
      description
      owner
      sourceID
      panelists {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerPersona = /* GraphQL */ `
  mutation CreateCustomerPersona(
    $input: CreateCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    createCustomerPersona(input: $input, condition: $condition) {
      id
      name
      persona_type
      personaID
      owner
      segmentID
      gender
      age
      education
      occupation
      marital_status
      income
      netWorth
      job_title
      industry
      company_size
      seniority
      role
      country
      city
      state
      region
      children
      race
      details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      userFeedback
      preference
      customerConversations {
        nextToken
        __typename
      }
      customerSurveyBriefs {
        nextToken
        __typename
      }
      customerInterviewBriefs {
        nextToken
        __typename
      }
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      ConceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      panelists {
        nextToken
        __typename
      }
      assistants {
        nextToken
        __typename
      }
      summary
      history
      memory
      Concepts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerPersona = /* GraphQL */ `
  mutation UpdateCustomerPersona(
    $input: UpdateCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    updateCustomerPersona(input: $input, condition: $condition) {
      id
      name
      persona_type
      personaID
      owner
      segmentID
      gender
      age
      education
      occupation
      marital_status
      income
      netWorth
      job_title
      industry
      company_size
      seniority
      role
      country
      city
      state
      region
      children
      race
      details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      userFeedback
      preference
      customerConversations {
        nextToken
        __typename
      }
      customerSurveyBriefs {
        nextToken
        __typename
      }
      customerInterviewBriefs {
        nextToken
        __typename
      }
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      ConceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      panelists {
        nextToken
        __typename
      }
      assistants {
        nextToken
        __typename
      }
      summary
      history
      memory
      Concepts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerPersona = /* GraphQL */ `
  mutation DeleteCustomerPersona(
    $input: DeleteCustomerPersonaInput!
    $condition: ModelCustomerPersonaConditionInput
  ) {
    deleteCustomerPersona(input: $input, condition: $condition) {
      id
      name
      persona_type
      personaID
      owner
      segmentID
      gender
      age
      education
      occupation
      marital_status
      income
      netWorth
      job_title
      industry
      company_size
      seniority
      role
      country
      city
      state
      region
      children
      race
      details
      attitudes
      interests
      lifestyle
      personality
      social
      values
      userFeedback
      preference
      customerConversations {
        nextToken
        __typename
      }
      customerSurveyBriefs {
        nextToken
        __typename
      }
      customerInterviewBriefs {
        nextToken
        __typename
      }
      qualProjectQueAnswers {
        nextToken
        __typename
      }
      ConceptAnswers {
        nextToken
        __typename
      }
      answers {
        nextToken
        __typename
      }
      panelists {
        nextToken
        __typename
      }
      assistants {
        nextToken
        __typename
      }
      summary
      history
      memory
      Concepts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQualProjectBrief = /* GraphQL */ `
  mutation CreateQualProjectBrief(
    $input: CreateQualProjectBriefInput!
    $condition: ModelQualProjectBriefConditionInput
  ) {
    createQualProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      questions
      audience
      type
      method
      qualProjectResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQualProjectBrief = /* GraphQL */ `
  mutation UpdateQualProjectBrief(
    $input: UpdateQualProjectBriefInput!
    $condition: ModelQualProjectBriefConditionInput
  ) {
    updateQualProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      questions
      audience
      type
      method
      qualProjectResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQualProjectBrief = /* GraphQL */ `
  mutation DeleteQualProjectBrief(
    $input: DeleteQualProjectBriefInput!
    $condition: ModelQualProjectBriefConditionInput
  ) {
    deleteQualProjectBrief(input: $input, condition: $condition) {
      id
      owner
      title
      objective
      questions
      audience
      type
      method
      qualProjectResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQueAnswer = /* GraphQL */ `
  mutation CreateQueAnswer(
    $input: CreateQueAnswerInput!
    $condition: ModelQueAnswerConditionInput
  ) {
    createQueAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      personaID
      panelistID
      qualProjectResultsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQueAnswer = /* GraphQL */ `
  mutation UpdateQueAnswer(
    $input: UpdateQueAnswerInput!
    $condition: ModelQueAnswerConditionInput
  ) {
    updateQueAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      personaID
      panelistID
      qualProjectResultsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQueAnswer = /* GraphQL */ `
  mutation DeleteQueAnswer(
    $input: DeleteQueAnswerInput!
    $condition: ModelQueAnswerConditionInput
  ) {
    deleteQueAnswer(input: $input, condition: $condition) {
      id
      question
      answer
      personaID
      panelistID
      qualProjectResultsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQualProjectResults = /* GraphQL */ `
  mutation CreateQualProjectResults(
    $input: CreateQualProjectResultsInput!
    $condition: ModelQualProjectResultsConditionInput
  ) {
    createQualProjectResults(input: $input, condition: $condition) {
      id
      owner
      audience
      type
      method
      qualProjectBriefID
      qualProjectAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQualProjectResults = /* GraphQL */ `
  mutation UpdateQualProjectResults(
    $input: UpdateQualProjectResultsInput!
    $condition: ModelQualProjectResultsConditionInput
  ) {
    updateQualProjectResults(input: $input, condition: $condition) {
      id
      owner
      audience
      type
      method
      qualProjectBriefID
      qualProjectAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQualProjectResults = /* GraphQL */ `
  mutation DeleteQualProjectResults(
    $input: DeleteQualProjectResultsInput!
    $condition: ModelQualProjectResultsConditionInput
  ) {
    deleteQualProjectResults(input: $input, condition: $condition) {
      id
      owner
      audience
      type
      method
      qualProjectBriefID
      qualProjectAnswers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerConversation = /* GraphQL */ `
  mutation CreateCustomerConversation(
    $input: CreateCustomerConversationInput!
    $condition: ModelCustomerConversationConditionInput
  ) {
    createCustomerConversation(input: $input, condition: $condition) {
      id
      owner
      messages {
        nextToken
        __typename
      }
      title
      customerPersonaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerConversation = /* GraphQL */ `
  mutation UpdateCustomerConversation(
    $input: UpdateCustomerConversationInput!
    $condition: ModelCustomerConversationConditionInput
  ) {
    updateCustomerConversation(input: $input, condition: $condition) {
      id
      owner
      messages {
        nextToken
        __typename
      }
      title
      customerPersonaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerConversation = /* GraphQL */ `
  mutation DeleteCustomerConversation(
    $input: DeleteCustomerConversationInput!
    $condition: ModelCustomerConversationConditionInput
  ) {
    deleteCustomerConversation(input: $input, condition: $condition) {
      id
      owner
      messages {
        nextToken
        __typename
      }
      title
      customerPersonaID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerMessage = /* GraphQL */ `
  mutation CreateCustomerMessage(
    $input: CreateCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    createCustomerMessage(input: $input, condition: $condition) {
      id
      owner
      message
      agent
      preference
      copied
      sources {
        url
        title
        text
        isAdded
        __typename
      }
      customerConversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerMessage = /* GraphQL */ `
  mutation UpdateCustomerMessage(
    $input: UpdateCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    updateCustomerMessage(input: $input, condition: $condition) {
      id
      owner
      message
      agent
      preference
      copied
      sources {
        url
        title
        text
        isAdded
        __typename
      }
      customerConversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerMessage = /* GraphQL */ `
  mutation DeleteCustomerMessage(
    $input: DeleteCustomerMessageInput!
    $condition: ModelCustomerMessageConditionInput
  ) {
    deleteCustomerMessage(input: $input, condition: $condition) {
      id
      owner
      message
      agent
      preference
      copied
      sources {
        url
        title
        text
        isAdded
        __typename
      }
      customerConversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerSurveyBrief = /* GraphQL */ `
  mutation CreateCustomerSurveyBrief(
    $input: CreateCustomerSurveyBriefInput!
    $condition: ModelCustomerSurveyBriefConditionInput
  ) {
    createCustomerSurveyBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        options
        __typename
      }
      questionText
      customerPersonaID
      customerSurveyResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerSurveyBrief = /* GraphQL */ `
  mutation UpdateCustomerSurveyBrief(
    $input: UpdateCustomerSurveyBriefInput!
    $condition: ModelCustomerSurveyBriefConditionInput
  ) {
    updateCustomerSurveyBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        options
        __typename
      }
      questionText
      customerPersonaID
      customerSurveyResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerSurveyBrief = /* GraphQL */ `
  mutation DeleteCustomerSurveyBrief(
    $input: DeleteCustomerSurveyBriefInput!
    $condition: ModelCustomerSurveyBriefConditionInput
  ) {
    deleteCustomerSurveyBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        options
        __typename
      }
      questionText
      customerPersonaID
      customerSurveyResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerSurveyResults = /* GraphQL */ `
  mutation CreateCustomerSurveyResults(
    $input: CreateCustomerSurveyResultsInput!
    $condition: ModelCustomerSurveyResultsConditionInput
  ) {
    createCustomerSurveyResults(input: $input, condition: $condition) {
      id
      owner
      customerSurveyBriefID
      customerSurveyAnswers {
        id
        question
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerSurveyResults = /* GraphQL */ `
  mutation UpdateCustomerSurveyResults(
    $input: UpdateCustomerSurveyResultsInput!
    $condition: ModelCustomerSurveyResultsConditionInput
  ) {
    updateCustomerSurveyResults(input: $input, condition: $condition) {
      id
      owner
      customerSurveyBriefID
      customerSurveyAnswers {
        id
        question
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerSurveyResults = /* GraphQL */ `
  mutation DeleteCustomerSurveyResults(
    $input: DeleteCustomerSurveyResultsInput!
    $condition: ModelCustomerSurveyResultsConditionInput
  ) {
    deleteCustomerSurveyResults(input: $input, condition: $condition) {
      id
      owner
      customerSurveyBriefID
      customerSurveyAnswers {
        id
        question
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerInterviewBrief = /* GraphQL */ `
  mutation CreateCustomerInterviewBrief(
    $input: CreateCustomerInterviewBriefInput!
    $condition: ModelCustomerInterviewBriefConditionInput
  ) {
    createCustomerInterviewBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        __typename
      }
      questionText
      customerPersonaID
      customerInterviewResults {
        nextToken
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerInterviewBrief = /* GraphQL */ `
  mutation UpdateCustomerInterviewBrief(
    $input: UpdateCustomerInterviewBriefInput!
    $condition: ModelCustomerInterviewBriefConditionInput
  ) {
    updateCustomerInterviewBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        __typename
      }
      questionText
      customerPersonaID
      customerInterviewResults {
        nextToken
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerInterviewBrief = /* GraphQL */ `
  mutation DeleteCustomerInterviewBrief(
    $input: DeleteCustomerInterviewBriefInput!
    $condition: ModelCustomerInterviewBriefConditionInput
  ) {
    deleteCustomerInterviewBrief(input: $input, condition: $condition) {
      id
      title
      objective
      owner
      numberOfQuestions
      questions {
        id
        question
        __typename
      }
      questionText
      customerPersonaID
      customerInterviewResults {
        nextToken
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerInterviewResults = /* GraphQL */ `
  mutation CreateCustomerInterviewResults(
    $input: CreateCustomerInterviewResultsInput!
    $condition: ModelCustomerInterviewResultsConditionInput
  ) {
    createCustomerInterviewResults(input: $input, condition: $condition) {
      id
      owner
      customerInterviewBriefID
      customerInterviewAnswers {
        id
        question
        answer
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerInterviewResults = /* GraphQL */ `
  mutation UpdateCustomerInterviewResults(
    $input: UpdateCustomerInterviewResultsInput!
    $condition: ModelCustomerInterviewResultsConditionInput
  ) {
    updateCustomerInterviewResults(input: $input, condition: $condition) {
      id
      owner
      customerInterviewBriefID
      customerInterviewAnswers {
        id
        question
        answer
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerInterviewResults = /* GraphQL */ `
  mutation DeleteCustomerInterviewResults(
    $input: DeleteCustomerInterviewResultsInput!
    $condition: ModelCustomerInterviewResultsConditionInput
  ) {
    deleteCustomerInterviewResults(input: $input, condition: $condition) {
      id
      owner
      customerInterviewBriefID
      customerInterviewAnswers {
        id
        question
        answer
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
