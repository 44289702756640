import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import GetUser from '../../utils/users/GetUser';

const PositioningBrief = (props) => {

    const [text, setText] = useState('');
    const { register, control, reset, handleSubmit } = useForm();
    const navigate = useNavigate();
    const persona = props.persona;

    let isTable=true;

    const onSubmit = async(data) => {
        console.log(data);
        props.handleSubmit(data);
    };


  return (
    <div className="flex flex-row flex-wrap w-full h-screen content-start bg-slate-50 drop-shadow-sm">
        <div className="flex flex-row w-full h-screen text-left align-middle">
            <div className="w-full h-full overflow-x-hidden">
                <div className="p-4 border-b-2">
                    <h5 className="text-black">
                        Create New Positioning
                    </h5>
                </div>
                <form className="flex flex-row flex-wrap w-10/12 h-fit" onSubmit={handleSubmit(onSubmit)}>


                    <div className="flex items-center w-full">
                    <label className="m-4 w-32">Examples of Good Positioning</label>
                        <textarea
                            id="good-positioning"
                            rows="2"
                            className="w-10/12 content-center rounded-lg border-1 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                            placeholder="Please enter the objective of this project in as much detail as possible"
                            required
                            {...register('objective')}></textarea>
                    </div>

                    <div className="flex items-center w-full">
                    <label className="m-4 w-32">Examples of Bad Positioning</label>
                        <textarea
                            id="good-positioning"
                            rows="2"
                            className="w-10/12 content-center rounded-lg border-1 m-4 px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400"
                            placeholder="Please enter the objective of this project in as much detail as possible"
                            required
                            {...register('objective')}></textarea>
                    </div>

                    <div className="flex flex-row w-full justify-end mt-4">
                        <label className="w-32"></label>
                        <button 
                            type="submit"
                            name='next' 
                            className="mr-20 mb-10 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div> 
    
  );
}

export default PositioningBrief;
