/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://lurqinhiargrjof4lotvlbfxfy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lmvyn3htbbhg5l3t57m4wc3lnq",
    "aws_cloud_logic_custom": [
        {
            "name": "llmjobdevapi",
            "endpoint": "https://r4c46bq3kc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "openaigptapi",
            "endpoint": "https://22o2pnykw2.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:ba188a36-3187-4553-959e-51f70e969332",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_sGkhvoHQo",
    "aws_user_pools_web_client_id": "5tki25jreeakn1gkajjmq6h9s2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "datastore4b4f05d27a5e4459a9687771f362214958-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
