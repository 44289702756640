// use state
import { useState, useEffect } from "react";
// AWS
import { Auth, API } from 'aws-amplify';
// local components
//import InteractionSidebar from "../navigation/InteractionSidebar";
//import InterviewQuestions from "./InterviewQuestions";
//import InterviewResults from "./InterviewResults";
//import InterviewBrief from "./InterviewBrief";

// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

import { useParams, useNavigate } from "react-router-dom";
import { set } from "react-hook-form";

// Loading
//import CircularProgress from '@mui/material/CircularProgress';
import Loading from "../../utils/Loading";

import SimpleLLMCall from "../../utils/llmcall/SimpleLLMCall";
import SimpleLLMCallJSON from "../../utils/llmcall/SimpleLLMCallJSON";
import { Save } from "@mui/icons-material";

import GetUser from "../../utils/users/GetUser";
import { CreateThreadLLM } from "../../utils/llmcall/CreateThreadLLM";
import { CreateMessageLLM } from "../../utils/llmcall/CreateMessageLLM";
import SaveLLMThreadDB from "../../utils/llmcall/SaveLLMThreadDB";
import GetLLMAssistantByPanelistID from "../../utils/llmcall/GetLLMAssistantByPanelistID";
import GetLLMAssistantByPersonaID from "../../utils/llmcall/GetLLMAssistantByPersonaID";

import ConceptsBrief from "./ConceptsBrief";
import ConceptsGenerated from "./ConceptsGenerated";
import SaveConcept from "../../utils/projects/SaveConcept";

// project workflow state
export const WorkflowStatus = {
    brief:"brief",
    generating: "generating",
    results:"results",
    completed: "completed",
}

// openai models
const model = "gpt-3.5-turbo";
const model2 = "gpt-4";

const ConceptsWorkflow = (props) => {

    console.log("Concepts Workflow");
    console.log(props);

    const { id } = useParams();
    const navigate = useNavigate();

    const persona = props.persona;
    const assistantID = props.MRAssistantID;
    //const goodPositioning = props.goodPositioning;
    //const goodMessaging = props.goodMessaging;
    // project concepts
    const [projectConcepts, setProjectConcepts] = useState([]);

    const [projectObjective, setProjectObjective] = useState(null);
    
    // user
    const [user, setUser] = useState(null); 
    // workflow status
    const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.brief);
    // project brief
    const [formData, setFormData] = useState(null);
    

    // fetch user
    useEffect(() => {     
        
        const fetchUser = async() => {
            const user = await GetUser();
            setUser(user);
        }

        fetchUser();

        console.log("User: ", user);
        
    },[])

    function format_llm_response(text) {
        // parse json response
        let concepts = JSON.parse(text);

        console.log(concepts);
        return concepts;
    }

    async function generateConcepts(data) {
        
        setWorkflowStatus(WorkflowStatus.generating);
        
        const systemPrompt = `You are tasked with coming up with ${data.conceptCount} marketing concepts for the following persona: ${persona.summary}. Return only the concepts in JSON format. For example, [{"id": 1, "title": "concept title", "description": "description of the concept"}, {"id": 2, "title": "concept title", "description": "description of the concept"}...]. Do not add any additional text in your response.`;
            
        let response;

        /*
        * Using ASSISTANT MODE
        */
        //console.log(props.MRAssistantID);

        // get the MR Assistant
        const assistantDB = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.getLLMAssistant,
            variables: {
                id: props.MRAssistantID,
            }
        });
        console.log("Assistant: ", assistantDB.data.getLLMAssistant);
        const LLMAssistant = assistantDB.data.getLLMAssistant.assistantID;
        console.log("Assistant ID: ", LLMAssistant);

        // create a thread
        let dataToLLM={}
        dataToLLM.model = model;
        dataToLLM.temperature = 0.5;
        dataToLLM.mode = "assistant";
        dataToLLM.llm_data = {
            assistantMode: "createThread",
        }
        const threadData =  await CreateThreadLLM(dataToLLM);
        // save thread to DB
        const threadDBData = {
            threadID: threadData.threadID,
            owner: user.attributes.email,
            assistantID: LLMAssistant,
        }
        console.log("Thread in DB data: ", threadDBData)
        const threadDBID = await SaveLLMThreadDB(threadDBData).catch(error => {
            console.error('Error saving LLM Thread:', error);
            console.error('Error details:', error.errors);
        });
        console.log("Thread in DB info: ", threadDBID);
        let messageData = {};
        messageData.model = model;
        messageData.temperature = 0.5;
        messageData.mode = "assistant";
        messageData.llm_data = {
            assistantMode: "createMessage",
            threadID: threadData.threadID,
            assistantID: LLMAssistant,
        };

        messageData.llm_data.message = systemPrompt;
        let messageResponse = await CreateMessageLLM(messageData);
        const conceptsFromLLM = messageResponse[0].message;
        const formattedConcepts = format_llm_response(conceptsFromLLM);
        
        console.log("Concepts after formatting: ");
        console.log(formattedConcepts);

        setProjectConcepts(formattedConcepts);

        setWorkflowStatus(WorkflowStatus.results);           
    }

    async function saveConcepts() {

        console.log("Saving concepts...");
        console.log(projectConcepts);

        // save the concepts to the db
        for (let i=0; i < projectConcepts.length; ++i) {            

            // save project concepts
            const conceptDB = {
                // id will be auto-populated by DynamoDB
                customerpersonaID: persona.id,
                title: projectConcepts[i].title,
                description: projectConcepts[i].description,            
            }
            // save project brief to the db
            console.log("Concept DB: ", conceptDB);
            const conceptID = await SaveConcept(conceptDB);
            projectConcepts[i].id = conceptID;
        }

        setWorkflowStatus(WorkflowStatus.completed);

        navigate("/segments/" + persona.id);
    }

    function editConcept(id, title, description) {
        //console.log("edit button");
        const newConcepts = projectConcepts.map(concept => 
            concept.id === id ? { ...concept, title: title, description: description } : concept
        );
        setProjectConcepts(newConcepts);
    }

    function deleteConcept(id) {
        let newConcepts = projectConcepts.filter(concept => concept.id !== id);
        newConcepts = newConcepts.map((concept, index) => ({ ...concept, id: index + 1 }));
        setProjectConcepts(newConcepts);
    }

    function addConcept(newConcept) {   
        const newConcepts = [...projectConcepts, { id: projectConcepts.length + 1, description: newConcept }];
        setProjectConcepts(newConcepts);
        //console.log(newQuestions);
    }

    const handleSubmit = (data) => {
        
        console.log(data);
        setFormData(data);

        // eventually use these to fine-tune the market research assistant
        //const goodConcepts = data.goodConcepts.split("\n");
        //const badConcepts = data.badConcepts.split("\n");

        //setProjectObjective(data.objective);

        generateConcepts(data);
        
    }

    return (
        <div className="flex flex-row w-full overflow-x-auto overflow-y-auto">        
            <div className="flex flex-col w-full h-full">
                <div className="w-full h-full bg-slate-50">
                    {workflowStatus === WorkflowStatus.brief && <ConceptsBrief handleSubmit={handleSubmit} />}
                    {workflowStatus === WorkflowStatus.generating && <div className="w-full h-full bg-slate-50 m-8 items-center content-center"> <h1 className="text-center align-middle">Generating concepts...</h1> <Loading /> </div>}
                    {workflowStatus === WorkflowStatus.results && <ConceptsGenerated objective={projectObjective} concepts={projectConcepts} edit={editConcept} delete={deleteConcept} add={addConcept} save={saveConcepts} />}
                    {/*{workflowStatus === WorkflowStatus.completed && <ProjectSurveyResultsForConcepts loading={loadingAnswers} objective={projectObjective} questions={projectQuestions} results={projectResults} respondents={respondents} concepts={projectConcepts} />}*/}
                </div>          
            </div>            
        </div>
    )
}

export default ConceptsWorkflow;