import React, { useRef, useState, useEffect } from 'react';
import addIcon from "../../img/icons8-add-30.png";
import editIcon from "../../img/icons8-edit-30.png";
import deleteIcon from "../../img/icons8-delete-30.png";
import saveIcon from "../../img/icons8-save-30.png";
import { set } from 'react-hook-form';

const ConceptsGenerated = (props) => {

    const objective = props.objective;
    const concepts = props.concepts;

    console.log("Generated Concepts: ", props.concepts);

    const [newConcept, setNewConcept] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editingDescription, setEditingDescription] = useState(null);
    const [editingTitle, setEditingTitle] = useState(null);

    function handleSubmit (data) {
        //console.log();
        props.save(data);
    }

    function editConcept (id) {
        const conceptToEdit = concepts.find(item => item.id === id);
        if (editingId === id) {
            setEditingId(null);
            props.edit(id, editingTitle, editingDescription);
        } else {
            setEditingId(id);
            setEditingTitle(conceptToEdit.title);
            setEditingDescription(conceptToEdit.description);
        }
    }

    function deleteConcept (id) {
        props.delete(id);
    }

    function addConcept () {
        props.add(newConcept);
        setNewConcept('');
    }

    const textareaRef = useRef(null)
    
    return (
        <div className="flex flex-col w-full content-start bg-slate-50">
        <div className="flex flex-row w-full justify-between content-center">
            <table className="table-auto w-full border border-slate-500">
                <thead className="content-start">
                    <tr className="border bg-slate-50 w-full">
                        <th className="px-4 py-4 text-center" colspan="4">
                            <h2>Marketing Concepts</h2>
                        </th>
                    </tr>
                    <tr className="border bg-slate-50 w-full">
                        <th className="px-4 py-4 text-left">Number</th>
                        <th className="px-4 py-4 text-left">Title</th>
                        <th className="px-4 py-4 text-left">Description</th>
                        <th className="px-4 py-4 text-left">Actions</th>
                    </tr>
                </thead>
                <tbody className="border w-full">
                        {concepts.map((item) => (

                                <tr key={item.id} className="border w-full">
                                    
                                    <td className="text-left p-4 w-fit">
                                        {item.id}
                                    </td>

                                    <td className="text-left p-2 w-fit">
                                        {editingId === item.id ? (
                                        <input 
                                            name="editTitle"
                                            type="text"
                                            defaultValue={item.title}
                                            onChange={e => setEditingTitle(e.target.value)}
                                            className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                        </input>
                                        ) : (
                                            item.title
                                        )}
                                    </td>
                                        
                                    <td className="text-left p-2 w-fit">
                                        {editingId === item.id ? (                                            
                                            <input 
                                                name="editDescription"
                                                type="text"
                                                defaultValue={item.description}
                                                onChange={e => setEditingDescription(e.target.value)}
                                                className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                                            </input>
                                        ) : (
                                            item.description
                                        )}
                                    </td>

                                    <td className="text-left w-auto">
                                        <div className="flex flex-row justify-center">
                                            <img 
                                                src={editingId === item.id ? saveIcon : editIcon}
                                                alt="Edit Concept"
                                                title="Edit Concept"
                                                className="w-6 h-6 m-1 cursor-pointer"
                                                onClick={() => editConcept(item.id)} />
                                            <img 
                                                src={deleteIcon} 
                                                alt="delete" 
                                                title="Delete Concept" 
                                                className="w-6 h-6 m-1 cursor-pointer" 
                                                onClick={() => deleteConcept(item.id)} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        
                        {/*    
                            <tr>
                                <td className="text-left p-4 w-full" colspan="3">
                                <input 
                                name="newConcept"
                                type="text"
                                placeholder="Enter Concept"
                                value={newConcept || ''}
                                onChange={e => setNewConcept(e.target.value)}
                                className="content-center rounded-lg border-1 w-full px-2 py-2 bg-slate-0 dark:bg-slate-900 px-0 text-base text-slate-800 dark:text-slate-200 focus:outline-none placeholder-slate-400">
                            </input>
                            </td>
                            <td className="p-4 w-full">
                                <img 
                                    src={addIcon} 
                                    alt="Add Concept" 
                                    title="Add Concept" 
                                    className="w-6 h-6 ml-2 mr-2 content-center cursor-pointer" 
                                    onClick={() => addConcept()} />
                            </td>
                            </tr>   
                        */}
                                              
                        </tbody>
                    </table>
                </div>

                    <div className="flex flex-row w-full justify-end content-center mt-4">
                        <button onClick={handleSubmit} className="mr-8 mb-8 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                        Next
                        </button>
                    </div>
                </div>
    )


}

export default ConceptsGenerated;