import { API, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const GetConceptsByPersonaID = async (id) => {

    let nextToken = null;
    let allConcepts = [];

    do {
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: queries.conceptsByCustomerpersonaID, 
            variables: {
                customerpersonaID: id,
                limit: 100, // Specify the number of items to return in each request
                nextToken: nextToken,
            }
        });

        const concepts = apiData.data.conceptsByCustomerpersonaID.items;
        allConcepts = [...allConcepts, ...concepts];
        nextToken = apiData.data.conceptsByCustomerpersonaID.nextToken;

    } while (nextToken);

    return allConcepts;
}

export default GetConceptsByPersonaID;