import React from 'react';
import editIcon from "../../img/icons8-edit-30.png"

const PersonaProfile = (props) => {

    const persona = props.persona;
    const personaType = persona.personaType;
    const path = "/personas/edit/" + persona.personaID;

    function editPersona (persona) {
        //console.log("PersonaProfile.editPersona: " + persona)
        props.editPersona(persona);
    }

    return (
        <div className="flex flex-row flex-wrap w-full py-8 px-8 mt-4 mb-2 ml-4 mr-4 space-y-0 justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
        <div className="w-full mb-4 py-8 px-8 space-y-0 bg-white justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
            <div className="justify-items-start sm:text-left">
                <div className="justify-items-start">
                <h5 className="text-left font-medium">
                    Demographics & Psychographics
                </h5>
                <hr />
                </div>
                <ul className="text-left font-medium list-disc px-4">                            
                    {(persona.age) && (<li key={persona.age}><b>Age:</b> {persona.age}</li>)}
                    {(persona.gender) && (<li key={persona.gender}><b>Gender:</b> {persona.gender}</li>)}                             
                    {(persona.race) && (<li key={persona.race}><b>Race:</b>  {persona.race }</li>)}
                    {(persona.education) && (<li key={persona.education}><b>Education:</b>  {persona.education}</li>)}
                    {(persona.occupation) && (<li key={persona.occupation}><b>Occupation:</b> {persona.occupation}</li>)}
                    {(persona.maritalStatus) &&(<li key={persona.maritalStatus}><b>Marital Status:</b> {persona.maritalStatus}</li>)}
                    {(persona.children) && (<li key={persona.children}><b>Children:</b> {persona.children}</li>)}
                    {(persona.income) && (<li key={persona.income}><b>Income:</b> {persona.income}</li>)}
                    {(persona.netWorth) && (<li key={persona.netWorth}><b>Net Worth:</b> {persona.netWorth}</li>)}
                    {(persona.city) && (<li key={persona.city}><b>City:</b> {persona.city}</li>)}
                    {(persona.values) &&(<li key={persona.values}><b>Values & Beliefs:</b> {persona.values}</li>)}
                    {(persona.lifestyle) && (<li key={persona.lifestyle}><b>Lifestyle & Activities:</b> {persona.lifestyle}</li>)}
                    {(persona.attitudes) && (<li key={persona.attitudes}><b>Attitudes & Opinions:</b> {persona.attitudes}</li>)}
                    {(persona.interests) && (<li key={persona.interests}><b>Interests & Hobbies:</b> {persona.interests}</li>)}
                    {(persona.personality) && (<li key={persona.personality}><b>Personality Traits:</b> {persona.personality}</li>)}
                    {(persona.social) && (<li key={persona.social}><b>Social & Cultural:</b> {persona.social}</li>)}
                </ul>
                </div>
                </div>
                </div>
    )
}

export default PersonaProfile;