// use state
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// Table component
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { ThemeProvider, createTheme } from '@mui/system';
import addIcon from "../../img/icons8-add-30.png"
import editIcon from "../../img/icons8-edit-30.png"
import deleteIcon from "../../img/icons8-delete-30.png"

const ConceptsTable = (props) => {

    console.log("ConceptsTable: ", props.concepts);
    // persona
    const concepts = props.concepts;
    // loading 
    const [isLoading, setIsLoading] = useState(props.isLoading);
    // user
    const [user, setUser] = useState(null);
    // navigate
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    const theme = createTheme({
        palette: {
            background: {
              paper: '#fff',
            },
            text: {
              primary: '#173A5E',
              secondary: '#46505A',
            },
            action: {
              active: '#001E3C',
            },
            success: {
              dark: '#009688',
            },
        },
    });

    const columns = [
        {field: 'title', headerName: 'Concept Title', width: 300},
        {field: 'description', headerName: 'Concept Description', width: 800},
    ]

    const handleSelectionChange = (newSelection) => {
        console.log(newSelection);
        //props.selectedPersonas(newSelection);
        //setSelectedPersonas(newSelection);
    }

    function addConcepts() {
        console.log("Concepts.addConcepts: " + concepts)
        navigate("concepts");
    }

    function editConcepts() {  
        console.log("Concepts.editConcepts: " + concepts)
    }

  return (

    <div className="flex flex-row flex-wrap w-full py-8 px-8 mt-0 mb-2 ml-4 mr-4 space-y-0 justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
        <div className="w-full mb-4 py-8 px-8 space-y-0 bg-white justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
            <div className="justify-items-start sm:text-left w-full">
            <div className="flex justify-between items-center">
                <h5 className="text-left font-medium">
                    Marketing Concepts
                </h5>
                <div className="flex flex-row justify-end space-x-4">
                        <button disabled={false} className="rounded-lg items-center text-center text-slate-500 hover:text-blue-600" type="button" onClick={() => addConcepts()}> 
                            <img src={addIcon} alt="Add Icon" /> 
                        </button>
                        <button hidden={true} className="rounded-lg items-center text-center text-slate-50 hover:text-blue-600" type="button" onClick={() => editConcepts()}> 
                            <img src={editIcon} alt="Edit Icon" /> 
                        </button>
                    </div>
                </div>
                
                <hr />
                        <DataGridPro
                            loading={isLoading}
                            autoHeight
                            //slots = {{ toolbar: GridToolbar }}
                            sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 0, 
                                    borderRadius: 4,
                                    border: 0,
                                    px: 2,
                                }}
                            rows={concepts}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            editMode="row"
                            pagination
                            pageSize={10}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            //checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                        />
                </div>
                </div>
                </div>
  )
};
  
export default ConceptsTable;