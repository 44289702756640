import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import GetConcepts from '../../utils/personas/GetConcepts';


const ProjectConcepts = (props) => {

    const [concepts, setConcepts] = useState([]);
    const [selectedConcepts, setSelectedConcepts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);
        
        const fetchConcepts = async() => {
            const concepts = await GetConcepts();
            console.log("Concepts: ", concepts);
            if (concepts) {
                setConcepts(concepts);
            }
        }

        fetchConcepts();

        setLoading(false);

    }, []);


    function handleSubmit (data) {
        // find the selected concepts in the concepts array
        const tempConcepts = concepts.filter(concept => selectedConcepts.includes(concept.id));
        console.log("Concepts: ", tempConcepts);
        data.concepts = tempConcepts;
        props.generateQuestions(data);
    }

    const handleSelectionChange = (newSelection) => {
        console.log(newSelection);
        setSelectedConcepts(newSelection);
    }

    const columns = [
        {field: 'title', headerName: 'Concept Title', width: 300},
        {field: 'description', headerName: 'Concept Description', width: 800},
    ]

    return (
        <div className="flex flex-col w-full content-center bg-slate-50 ">
                <div className="w-full justify-between content-center p-4">
                    <h3>Select the Concepts</h3>
                </div>
                <hr />
                <div className="w-full justify-between content-center p-4 ">
                    <DataGridPro
                            loading={loading}
                            autoHeight
                            //slots = {{ toolbar: GridToolbar }}
                            sx={{   
                                    fontWeight: 'fontWeightLight',
                                    bgcolor: 'white',
                                    boxShadow: 0, 
                                    borderRadius: 4,
                                    border: 1,
                                    px: 2,
                                }}
                            rows={concepts}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            editMode="row"
                            pagination
                            pageSize={10}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                        />
                </div>
                <div className="flex flex-row w-full justify-end mt-4">
                        <label className="w-32"></label>
                        <button 
                            type="submit"
                            name='next' 
                            onClick = {() => handleSubmit(selectedConcepts)}
                            className="mr-20 mb-10 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                            Next
                        </button>
                </div>
        </div>

                        
    )


}

export default ProjectConcepts;