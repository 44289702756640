import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const SaveConcept = async (data) => {
    console.log("SaveConcept data")
    console.log(data)

    try {
    
        const apiData = await API.graphql({ 
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.createConcepts, // change this after we know DB schema
            variables: {
                input: data,
            }
        });

        return apiData.data.createConcepts.id; 
    } catch (error) {
        console.error("SaveConcept error: ", error);
        return null;
    }  
}

export default SaveConcept;