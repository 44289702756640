// react router
import { Routes, Route, useNavigate } from 'react-router-dom';
// state
import { useEffect, useState } from 'react';
// custom components
//import PersonaDashboard from './Personas';
// AWS
import { API, Auth, Storage } from 'aws-amplify';
//import AWS from 'aws-sdk';
import awsconfig from '../aws-exports';

// GraphQL
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
// persona dashnboards
import SegmentTable from './personas/SegmentTable';
import Personas from './personas/Personas';
import PersonaTable from './personas/PersonaTable';
import Projects from './projects/Projects';
import Profiles from './panels/Profiles';
import Panels from './panels/Panels';
import GetPanels from '../utils/panels/GetPanels';
import GetProfiles from '../utils/panels/GetProfiles';
import GetUser from '../utils/users/GetUser';
import GetPersonas from '../utils/personas/GetPersonas';
//import GetInterviewBriefs from '../utils/interviews/GetInterviewBriefs';
//import GetSurveyBriefs from '../utils/surveys/GetSurveyBriefs';
import GetQualProjectResults from '../utils/qualProjects/GetQualProjectResults';
import GetProjectBriefs from '../utils/projects/GetProjectBriefs';
import GetSegments from '../utils/personas/GetSegments';
import GetPanelistsbyPanelId from '../utils/panels/GetPanelistsbyPanelId';
import { CreateAssistantLLM } from '../utils/llmcall/CreateAssistantLLM';
import GetPersonaByID from '../utils/personas/GetPersonaByID';
import GetLLMAssistantByPersonaID from '../utils/llmcall/GetLLMAssistantByPersonaID';
import GetPanelistByID from '../utils/panels/GetPanelistByID';
import GetLLMAssistantByPanelistID from '../utils/llmcall/GetLLMAssistantByPanelistID';
//import GetPersonasBySegmentID from '../utils/personas/GetPersonasBySegmentID';

const MainDashboard = (props) => {
    console.log("Main Dashboard");
    console.log(props.MRAssistantID)

    const [user, setUser] = useState(null);
    const [personas, setPersonas] = useState([]);
    const [projects, setProjects] = useState([]);
    const [qualProjects, setQualProjects] = useState([]);
    const [quantProjects, setQuantProjects] = useState([]);
    const [conceptList, setConceptList] = useState([]);
    const [segments, setSegments] = useState([]);
    const [panels, setPanels] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [personaOrArchetype, setPersonaOrArchetype] = useState("Personas");
    const [displayFormat, setDisplayFormat] = useState('Table');

    async function fetchUser() {
        const user = await GetUser();
        if (user) {
            setUser(user);
            if (user.attributes.email.includes("suntory")) {
                setPersonaOrArchetype("Archetypes");
              }      
        }
    }

    async function fetchSegments() {
        const segments = await GetSegments();
        if (segments) {
            setSegments(segments);
        }
    }

    async function fetchPanels() {
        const panels = await GetPanels();
        if (panels) {
            setPanels(panels);
        }
    }

    async function fetchProfiles() {
        const profiles = await GetProfiles();
        if (profiles) {
            setProfiles(profiles);
            setIsLoading(false);
        }
    }

    async function fetchPersonas() {
        const segments = await GetSegments();
        console.log("Segments: ", segments);
        if (segments) {
            const personas = await GetPersonas();
            console.log("Personas: ", personas);
            const updatedPersonas = personas.map(persona => {
                const segment = segments.find(segment => segment.id === persona.segmentID);
                if (!segment) {
                    return persona;
                } else {
                    return {...persona, segment: segment.name};
                }
            });
            setPersonas(updatedPersonas);
        }        
    }

    async function fetchQualProjects() {
        const qualProjects = await GetQualProjectResults();
        setQualProjects(qualProjects);
        setProjects(prevProjects => {
            const newProjects = qualProjects.filter(
                qp => !prevProjects.some(pp => pp.id === qp.id)
            );
            return [...prevProjects, ...newProjects];
        });
    }

    async function fetchProjectBriefs() {
        const projectBriefs = await GetProjectBriefs();
        setProjects(prevProjects => {
            const newProjects = projectBriefs.filter(
                pb => !prevProjects.some(pp => pp.id === pb.id)
            );
            return [...prevProjects, ...newProjects];
        });
    }

    
   
        // grab the personas from the database
    useEffect(() => {

        fetchUser()
        fetchSegments()
        fetchPersonas()
        fetchPanels()
        fetchProfiles()
        fetchProjectBriefs()
        //fecthMRAssistant()
        
    }, []);

    async function getJobStatusWithBackoff(jobId) {
        const path = '/jobs/' + jobId;
        let attempts = 0;
        let response;

        while (true) {
            response = await API.get('llmjobdevapi', path);
            console.log(response);

            if (response.status === 'completed') {
                console.log('Job completed!', response);
                break;
            } else {
                console.log('Job not yet completed');
                attempts++;
                let delay = Math.min(Math.pow(2, attempts) * 1000, 30000);
                console.log(`Retrying in ${delay}ms...`);
                await new Promise(resolve => setTimeout(resolve, delay));
                if (attempts >  10) {
                    console.log('Job taking too long to complete');
                    return {status: 'failed', result: 'Job taking too long to complete'};
                }
            }
        }

        return response;
    }

    const testAPI = async () => {
        
        const model = "gpt-3.5-turbo"
        const temperature = 0.5;
        let jobID = 0;
        let data;
        const mode = "assistant";

        
        // read from S3
        //const filename = "einstein.pdf";
     /*   const filename = "story.pdf";

        const url = await Storage.get(filename);
        const urlParts = new URL(url);
        const key = urlParts.pathname.substring(1);
        console.log(key);

        const model = "gpt-3.5-turbo"
        const temperature = 0.5;
        let jobID = 0;
        let data;
        
        console.log("Testing API");
        */

        // Step 1: create a new open ai assistant
        /*const mode = "assistant";
        
        data = {
            //instructions: "You are Albert Einstein. Please provide your answers in the same format as the questions. Thank you.",
            //name: "Einstein",
            instructions: "You are Harry Potter helper. answer questions if they are about Harry Potter. Thank you.",
            name: "HPBot",
            assistantMode: "createAssistant",
            //filename: key
        }
        const LLMData = {
            
                model: model,
                temperature: temperature,
                mode: mode,
                llm_data: data
            
        }
        const assistantData = await CreateAssistantLLM(LLMData);
        console.log(assistantData);*/
        /*await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    mode: mode,
                    data: data
                }
            }
        }).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        });*/
        // get the assistant ID
        //const assistant_response = await getJobStatusWithBackoff(jobID);
        //console.log(assistant_response);
        //const assistatID_obj = JSON.parse(assistant_response.result);
        //const assistantID = assistatID_obj.assistantID;
        //console.log(assistant_response.result);
        //const assistantID = JSON.parse(assistant_response.result).assistantID;
        //console.log(assistantID);
        //const fileID = assistant_response.result.fileID;
        //console.log(fileID);
        

        // Step 2: create a thread
       /* data = {
            assistantMode: "createThread",
        }
        await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    mode: mode,
                    data: data
                }
            }
        }).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
        // get the thread ID
        const thread_response = await getJobStatusWithBackoff(jobID);
        console.log(thread_response);
        const threadID_obj = JSON.parse(thread_response.result);
        const threadID = threadID_obj.threadID;
        console.log(threadID);
        
*/
        // Step 3:  add message to thread, get message from thread using assistantID
        //const assistantID = "asst_cIP1UGhuHoQ3OX4AD6MuAUUF";
        //const threadID = "thread_TfpsyXZgPRTFowx1EjRgGTqi";
        // Story Bot
        const assistantID = "asst_C4adOSz6klCNA6f8TNHDsixT";
        const threadID = "thread_GLL1X7YKvQ2hm4tYwmV1xvRG";
        // get the messages

        data = {
            assistantMode: "createMessage",
            threadID: threadID,
            message: "What animals meet in the story? give me their names.",
            assistantID: assistantID
        }
        await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    mode: mode,
                    data: data
                }
            }
        }).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
        




        //const message = "what was einstein ranked in a 1999 poll of 130 leading physicists worldwide by the British journal Physics World?";
        //const message = "What was your rank in the greatest physicist of all time poll by British journal Physics World?"
        /*const message = "Provide a summary of the story."
        data = {
            assistantID: assistantID,
            assistantMode: "message",
            message: message,
            threadID: threadID,
            //filename: key
        }
        */
        /*await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    mode: mode,
                    data: data
                }
            }
        }).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
        */
        const response = await getJobStatusWithBackoff(jobID);
        if (response.status === 'failed') {
            console.log("Job failed");
            return;
        }
        //console.log(response);
        const messages_response_str = response.result;
        console.log(messages_response_str);
        const messages_response = JSON.parse(messages_response_str).messages;
        console.log(messages_response);
        
        for (let i = 0; i < messages_response.length; i++) {
            // print entire message object
            console.log(messages_response[i]);
            // print the message ID for this message
            console.log("Message ID:", messages_response[i].id);
            // print the role for this message
            console.log("Role:", messages_response[i].role);
            // print the message
            console.log("Message:", messages_response[i].content[0].text.value);  
        }
        
    




        

        /*await API.post('llmjobdevapi', '/jobs', {
            body : {
                input : {
                    model: model,
                    temperature: temperature,
                    response_format: response_format,
                    mode: mode,
                    data: data
                }
            }
        }
        ).then(response => {
            jobID = response.jobID;
            console.log(response);
        }).catch(error => {
            console.log(error);
        })
        // get the job status using the jobID
        console.log("Getting job status")
        await getJobStatusWithBackoff(jobID);
        */
        
        console.log("Testing API complete")
    }

    async function test2API() {
        console.log("Testing API 2");

        /*
        * Test the API for getting assistant from panel ID and persona ID
        */

        // Persona ID Test: PASSED
        // sample persona ID
        //const personaID = "55742880-c7bd-448b-84e7-844452be7622";

        // get the persona details from ID
        //GetPersonaByID
        //const persona = await GetPersonaByID(personaID);
        //console.log(persona);

        // get the assistant ID
        //const assistant = await GetLLMAssistantByPersonaID(personaID);
        //console.log(assistant);

        // Panel ID Test:
        // sample panelist ID - Karen Smith
        const panelistID = "f6fbb39e-34b5-4750-9175-36540cf9fda5";

        // get the panelist from ID
        //const panelist = await GetPanelistByID(panelistID);
        //console.log(panelist);

        // get assistant from panelist ID
        const assistant = await GetLLMAssistantByPanelistID(panelistID);
        console.log(assistant);

    }

    /*async function testPyLambda() {
        console.log("Testing Py Lambda");
        API.get('pyetlapi', '/mp3').then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
        await Auth.currentCredentials().then (creds => {
            const lambda = new AWS.Lambda({
                credentials: Auth.essentialCredentials(creds),
                region: awsconfig.aws_project_region
            });

            const params = {
                FunctionName: 'pyetl-dev', 
                InvocationType: 'RequestResponse',
                Payload: JSON.stringify({ key1: 'value1', key2: 'value2' }) 
            };

            lambda.invoke(params, function(err, data) {
                if (err) {
                  console.log(err, err.stack);
                } else {
                  console.log(data);
                }
              });
        }).catch(err => {
            console.log(err);
        });
    
    }*/

    return (
        <div className="flex flex-row flex-wrap bg-slate-50 w-full">

            {/* testing new API */}

 {/*}           <div className="flex w-full justify-center">
                <button 
                    //onClick={() => testAPI()} 
                    onClick={() => testPyLambda()}
                    className="mt-10 mr-20 mb-10 px-4 py-2 text-sm text-blue-600 font-semibold rounded-md border border-blue-200 disabled:bg-slate-500 disabled:text-black hover:text-white hover:bg-blue-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                        Test PyLambda
                </button>
            </div>
    */}
             
    
            <div className="flex w-full justify-center mt-4">
                <h3 className="text-black">
                    Panels
                </h3>
            </div>
            <div className="flex w-full h-full">
                <Panels panels={panels} loading={isLoading} /> 
            </div>

            <div className="flex w-full justify-center">
                <h3 className="text-black">
                    Segments
                </h3>
            </div>
            <div className="flex w-full">
                <SegmentTable segments={segments} /> 
            </div>
            
            <div className="flex w-full justify-center">
                <h3 className="text-black">
                    Projects
                </h3>
            </div>
            <div className="flex w-full">
                <Projects projects={projects} qualProjects={qualProjects} quantProjects={quantProjects} conceptProjects={conceptList}/>
            </div>

        </div>        
        );
}

export default MainDashboard;