// react router
import { Routes, Route, useNavigate } from 'react-router-dom';
// state
import { useEffect, useState } from 'react';
// AWS
import { API, Auth } from 'aws-amplify';
// GraphQL
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
// persona chat
import Persona from '../personas/Persona';
import Projects from './Projects';
import ProjectSidebar from '../../utils/navigation/ProjectSidebar';
import NewProject from './ProjectBrief';
import NewProjectWorkflow from './NewProjectWorkflow';
import InterviewResults from '../../utils/interviews/InterviewResults';
import ProjectResults from './ProjectResults';
import SurveyResults from '../../utils/surveys/SurveyResults';
import { set } from 'react-hook-form';
import GetQualProjectResults from '../../utils/qualProjects/GetQualProjectResults';
import GetProjectBriefs from '../../utils/projects/GetProjectBriefs';
import GetConceptAnswersByProjectBriefID from '../../utils/projects/GetConceptAnswersByProjectBriefID';
import ProjectSurveyResultsForConcepts from './ProjectSurveyResultsForConcepts';
import ProjectInterviewResultsForConcepts from './ProjectInterviewResultsForConcepts';
import GetInsightAnswersByProjectBriefID from '../../utils/projects/GetInsightAnswersByProjectBriefID';
import ProjectSurveyResultsForInsights from './ProjectSurveyResultsForInsights';
import ProjectInterviewResultsForInsights from './ProjectInterviewResultsForInsights';

const ProjectDashboard = (props) => {
    console.log(props.MRAssistantID)

    const [menuPath, setMenuPath] = useState("/projects");
    const [user, setUser] = useState(null); // user is null if not logged in
    const [projectList, setProjectList] = useState([]);
    const [qualResearchList, setQualResearchList] = useState([]);
    const [quantResearchList, setQuantResearchList] = useState([]);
    const [conceptsList, setConceptsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedProject, setSelectedProject] = useState([]);
    const [projectResults, setResults] = useState([]);
    const [projectConcepts, setConcepts] = useState([]);
    const [projectQuestions, setQuestions] = useState([]);
    const [projectObjective, setProjectObjective] = useState("");
    const [projectRespondents, setRespondents] = useState(0);

    //const [selectedProjectResultsID, setSelectedProjectResultsID] = useState(null);
    const projectMethods = [
        {id: 1, name: "Qualitative"},
        {id: 2, name: "Quantitative"},
    ];

    const navigate = useNavigate();

    // transform the concepts for display to the component
    const formatConcepts = (datainput) => {       
            
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "type": item.type,
                "method": "Qualitative",
                "audience": item.audience,
                "date": formattedDate,
            };
        })
    }

    // transform interview brief titles to format for display to the component
    const formatInterviewBriefs = (datainput) => {       
            
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "type": item.type,
                "objective": item.objective,
                "method": "Qualitative",
                "audience": item.audience,
                "date": formattedDate,
            };
        })
    }

    // transform survey brief titles to format for display to the component
    const formatSurveyBriefs = (datainput) => {       
            
        return datainput.map(item => {
            const dateObj=new Date(item.createdAt)
            // Array of short month names
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // Format the date as "Mon day", e.g., "Aug 11"
            const formattedDate = `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()}`;

            // Return the transformed object
            return {
                "id": item.id,
                "title": item.title,
                "type": item.type,
                "objective": item.objective,
                "method": "Quantitative",
                "audience": item.audience,
                "date": formattedDate,
            };
        })
    }

    // fetch user and conversations
    useEffect(() => {    

        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);

            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }

        const fetchConcepts = async() => {

            setIsLoading(true);

            const userInfo = await Auth.currentAuthenticatedUser();
            console.log("userInfo")
            console.log(userInfo)
            // get personaID
            let result;            
            
            // get all the interviews for this persona                               
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listConcepts,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }
            console.log("result")
            console.log(result)

            let conceptsList = result.data.listConcepts.items;
            
            conceptsList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateB-dateA
            })

            //console.log("List of all interview briefs")
            //console.log(interviewBriefList)

            const formattedConcepts = formatConcepts(conceptsList)
            setConceptsList(formattedConcepts);
            setProjectList(prevProjectList => [...prevProjectList, ...formattedConcepts]);
            setIsLoading(false);
        }

        const fetchInterviews = async() => {

            setIsLoading(true);

            const userInfo = await Auth.currentAuthenticatedUser();
            // get personaID
            let result;            
            
            // get all the interviews for this persona                               
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerInterviewBriefs,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }

            let interviewBriefList = result.data.listCustomerInterviewBriefs.items;
            
            interviewBriefList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateB-dateA
            })

            //console.log("List of all interview briefs")
            //console.log(interviewBriefList)

            const formattedInterviews = formatInterviewBriefs(interviewBriefList)
            setQualResearchList(formattedInterviews);
            setProjectList(prevProjectList => [...prevProjectList, ...formattedInterviews]);
            setIsLoading(false);
        }

        const fetchSurveys = async() => {

            setIsLoading(true);

            const userInfo = await Auth.currentAuthenticatedUser();
            
            // get personaID
            let result;            
            // get all the surveys for this persona                               
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: queries.listCustomerSurveyBriefs,
                    variables: {
                        filter: {
                            owner: {eq: userInfo.attributes.email},
                        }
                    },                
                });
            } catch(err) {
                console.error(err);
            }

            let surveyBriefList = result.data.listCustomerSurveyBriefs.items;
            surveyBriefList.sort((a,b) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateB-dateA
            })
            
            //console.log("List of all survey briefs")
            //console.log(surveyBriefList)

            const formattedSurveys = formatSurveyBriefs(surveyBriefList)
            setQuantResearchList(formattedSurveys)
            setProjectList(prevProjectList => [...prevProjectList, ...formattedSurveys]);            
            setIsLoading(false);
        }

        

        const fetchQualProjects = async() => {
            setIsLoading(true);

            const formattedQualProjects = await GetQualProjectResults();

            setQualResearchList(formattedQualProjects);
            setProjectList(prevProjectList => {
                const newProjects = formattedQualProjects.filter(
                    fp => !prevProjectList.some(pp => pp.id === fp.id)
                );
                return [...prevProjectList, ...newProjects]
            });

            setIsLoading(false);
        }

        const fetchProjectBriefs = async() => {
            setIsLoading(true);
            const formattedProjectBriefs = await GetProjectBriefs();
            console.log("fetchProjectBriefs");
            console.log(formattedProjectBriefs);
            setProjectList(prevProjectList => {
                const newProjects = formattedProjectBriefs.filter(
                    fp => !prevProjectList.some(pp => pp.id === fp.id)
                );
                return [...prevProjectList, ...newProjects]
            });
            setIsLoading(false);
        }

        fetchUser();
        fetchProjectBriefs();
        //fetchInterviews();
        //fetchSurveys();
        //fetchQualProjects();
        //fetchConcepts();

    }, []);

    async function GetConceptAnswers(projectBriefID) {

        setIsLoading(true);
        const fetchedResults = await GetConceptAnswersByProjectBriefID(projectBriefID);
        console.log("Project Results: ", fetchedResults);
        setResults(fetchedResults.results);
        setProjectObjective(fetchedResults.objective);
        setRespondents(fetchedResults.respondents);
        setConcepts(fetchedResults.concepts);
        setQuestions(fetchedResults.questions);
        setIsLoading(false);

    }

    async function GetInsightAnswers(projectBriefID) {
        setIsLoading(true);
        const fetchedResults = await GetInsightAnswersByProjectBriefID(projectBriefID);
        console.log("Project Results: ", fetchedResults);
        console.log(fetchedResults.results);
        setResults(fetchedResults.results);
        setProjectObjective(fetchedResults.objective);
        setRespondents(fetchedResults.respondents);
        setConcepts(fetchedResults.concepts);
        setQuestions(fetchedResults.questions);
        setIsLoading(false);

    }

    function updateMenu(id) {
        
        if (id === 1) {
            setProjectList(null);
            setMenuPath("/projects/new");
            navigate("/projects/new");
            return;
        }

        const project = projectList.find((item) => item.projectID === id);
        const path = "/projects/" + project.projectID;
        
        //setProject(project);        
        //setPersonaID(persona.personaID)
        setMenuPath(path);
        navigate(path);
    }

    async function updateSubmenu(project) {
        console.log("Selected Project: ", project);
        setResults([]);
        setSelectedProject(project);

        let menuPath = "/projects";

        if (project.method === "Qualitative") {
            menuPath = "/projects/qualitative/" + project.id;
        } else  {
            menuPath = "/projects/quantitative/" + project.id;
        }

        if (project.type === "Concept Testing") {
            GetConceptAnswers(project.id);
        } else if (project.type === "Consumer Insights") {
            GetInsightAnswers(project.id);
        }
        console.log("Menu Path: ", menuPath);
        navigate(menuPath);
    }

    function newProject() {
        navigate("/projects/new");
    }

    return (
        <div className="flex flex-row w-full bg-slate-50 overflow-x-auto overflow-y-auto">
            <ProjectSidebar menuItems={projectMethods} submenuItems={projectList} updateMenu={updateMenu} updateSubmenu={updateSubmenu} /> 
                    <Routes >
                        <Route path="/new" element={<NewProjectWorkflow MRAssistantID={props.MRAssistantID}/>} />
                        {selectedProject.type === 'Concept Testing' && selectedProject.method === 'Qualitative' && <Route path="/qualitative/:projectID" element={<ProjectInterviewResultsForConcepts loading={isLoading} objective={projectObjective} respondents={projectRespondents} questions={projectQuestions} results={projectResults} concepts={projectConcepts} />} /> }
                        {selectedProject.type === 'Concept Testing' && selectedProject.method === 'Quantitative' && <Route path="/quantitative/:projectID" element={<ProjectSurveyResultsForConcepts loading={isLoading} objective={projectObjective} respondents={projectRespondents} questions={projectQuestions} results={projectResults} concepts={projectConcepts} />} /> }
                        {selectedProject.type === 'Consumer Insights' && selectedProject.method === 'Qualitative' && <Route path="/qualitative/:projectID" element={<ProjectInterviewResultsForInsights loading={isLoading} objective={projectObjective} respondents={projectRespondents} questions={projectQuestions} results={projectResults} concepts={projectConcepts} />} /> }
                        {selectedProject.type === 'Consumer Insights' && selectedProject.method === 'Quantitative' && <Route path="/quantitative/:projectID" element={<ProjectSurveyResultsForInsights loading={isLoading} objective={projectObjective} respondents={projectRespondents} questions={projectQuestions} results={projectResults} concepts={projectConcepts} />} /> }    
                        <Route path="/*" element={<Projects projects={projectList} qualProjects={qualResearchList} quantProjects={quantResearchList} conceptProjects={conceptsList} newProject={newProject} loading={isLoading} />} />
                    </Routes>
        </div>    
    );
}

export default ProjectDashboard;