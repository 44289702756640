import { useEffect, useState } from 'react';
import { Auth, API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, set } from  'react-hook-form';
import { useDropzone } from 'react-dropzone';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { FormatColorReset } from '@mui/icons-material';
import PersonaBrief from './PersonaBrief';
import PersonaResults from './PersonaResults';
import * as personaoptions from '../../utils/personas/PersonaOptions';
import Loading from '../../utils/Loading';
import { DataGrid } from '@mui/x-data-grid';
import GetPanels from '../../utils/panels/GetPanels';
import GetUser from '../../utils/users/GetUser';
import GetPanelistsbyPanelId from '../../utils/panels/GetPanelistsbyPanelId';
import GetInsightAnswersByPanelistID from '../../utils/projects/GetInsightAnswersByPanelistID';
import SimpleLLMStartPoll from '../../utils/llmcall/SimpleLLMStartPoll';
import { CreateAssistantLLM } from '../../utils/llmcall/CreateAssistantLLM';
import SaveLLMAssistantDB from '../../utils/llmcall/SaveLLMAssistantDB';
import { CreateThreadLLM } from '../../utils/llmcall/CreateThreadLLM';
import SaveLLMThreadDB from '../../utils/llmcall/SaveLLMThreadDB';
import * as queries from '../../graphql/queries';
import { file } from 'jszip';
import { CreateMessageLLM } from '../../utils/llmcall/CreateMessageLLM';

// project workflow state
export const WorkflowStatus = {
    brief:"brief",
    results:"results",
    ploading: "cloading",
    rloading: "rloading",
}

// testdata
const personaTests = [
    {
        "name": "Persona 1", "id": "1",   
    },
    {
        "name": "Persona 2", "id": "2",
    }
];

// openai models
const model = "gpt-3.5-turbo";
const model2 = "gpt-4";

const system_prompt_criteria = 
    `You are a market researcher. You are tasked with creating 5 personas for a customer segment.
    Return the personas in a list and no other text in JSON format: [<Persona 1>, <Persona 2>,...]
    Each persona has the following fields in JSON format:
    <Persona> :
    {"name", "gender", "age", "race", "education", "occupation", "marital_status", "children", "netWorth", "income", "city", "state", "country", "values", "lifestyle", "interests", "attitudes", "personality", "social"}.
    Here is the description of the fields:
    {"name" : unique nickname composed of 2-3 words, "gender" : gender, "age" : age range, "race" : race, "education" : education, "occupation" : occupation, "marital_status" : marital status, "children" : number of children, "netWorth" : net worth range in US dollars, "income" : income range in US dollars, "city" : city where persona lives, "state" : state of the city where persona lives, "country" : country of the state where persona lives, "values": 2-3 sentences describing values and beliefs of the persona, "lifestyle": 2-3 sentences describing lifestyle and activities of the persona, "interests": 2-3 sentences describing interests and hobbies of the persona, "attitudes": 2-3 sentences describing attitudes and opinions of the persona, "personality": 2-3 sentences describing personality traits of the persona, "social": 2-3 sentences describing social and cultural factors that influence the persona}
    Use pronouns such as "he", "she", "they" to refer to the persona.
    `
const system_prompt_criteria_single_persona = 
    `You are a market researcher. You are tasked with creating 1 persona.
    Return the persona and no other text in JSON format: [<Persona 1>]
    The persona has the following fields in JSON format:
    <Persona> :
    {"name", "gender", "age", "race", "education", "occupation", "marital_status", "children", "netWorth", "income", "city", "state", "country", "values", "lifestyle", "interests", "attitudes", "personality", "social"}.
    Here is the description of the fields:
    {"name" : unique nickname composed of 2-3 words, "gender" : gender, "age" : age range, "race" : race, "education" : education, "occupation" : occupation, "marital_status" : marital status, "children" : number of children, "netWorth" : net worth range in US dollars, "income" : income range in US dollars, "city" : city where persona lives, "state" : state of the city where persona lives, "country" : country of the state where persona lives, "values": 2-3 sentences describing values and beliefs of the persona, "lifestyle": 2-3 sentences describing lifestyle and activities of the persona, "interests": 2-3 sentences describing interests and hobbies of the persona, "attitudes": 2-3 sentences describing attitudes and opinions of the persona, "personality": 2-3 sentences describing personality traits of the persona, "social": 2-3 sentences describing social and cultural factors that influence the persona}
    Use pronouns such as "he", "she", "they" to refer to the persona.
    `

const system_prompt_panel = 
    `You are a market researcher. You are tasked with creating 5 personas for a customer segment.
    You will be provided a list of profiles of panelists. You will use these profiles to create personas.
    Pay particular attention to the values, lifestyle, interests, attitudes, personality, and social and cultural factors of the panelists.
    Pay particular attention to question and answers from interview conducted with the panelists, if available. They provide additional insights into the panelists and criteria for creating personas.
    Each panelist has the following fields in JSON format: <Panelist> -{"panelist":{name:<>, ... interview:[{question:<>, answer:<>}, ...]}, {panelist:{name:<>, ..., interview:[{question:<>, answer:<>}, ...]}, ...}
    Identify key themes and patterns in the profiles and use these to create the personas.
    Return the personas in a list and no other text in JSON format: [<Persona 1>, <Persona 2>,...]
    Each persona has the following fields in JSON format:
    <Persona> :
    {"name", "gender", "age", "race", "education", "occupation", "marital_status", "children", "netWorth", "income", "city", "state", "country", "values", "lifestyle", "interests", "attitudes", "personality", "social"}.
    Here is the description of the fields:
    {"name" : unique nickname composed of 2-3 words that is different from nicknames of other profiles, "gender" : gender, "age" : age range, "race" : race, "education" : education, "occupation" : occupation, "marital_status" : marital status, "children" : number of children, "netWorth" : net worth range in US dollars, "income" : income range in US dollars, "city" : city where persona lives, "state" : state of the city where persona lives, "country" : country of the state where persona lives, "values": 2-3 sentences describing values and beliefs of the persona, "lifestyle": 2-3 sentences describing lifestyle and activities of the persona, "interests": 2-3 sentences describing interests and hobbies of the persona, "attitudes": 2-3 sentences describing attitudes and opinions of the persona, "personality": 2-3 sentences describing personality traits of the persona, "social": 2-3 sentences describing social and cultural factors that influence the persona}
    Use pronouns such as "he", "she", "they" to refer to the persona.
    `

const NewPersonaWorkflow = (props) => {   
    console.log("NewPersonaWorkflow starts")
    console.log(props.MRAssistantID);
    // workflow status
    const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.brief);
    //const [workflowStatus, setWorkflowStatus] = useState(WorkflowStatus.results);
    // user
    const [user, setUser] = useState(null);
    // persona list
    const [personaList, setPersonaList] = useState([]);
    // segmentID
    const [segmentID, setSegmentID] = useState(null);
    // segment name
    const [segmentName, setSegmentName] = useState("");
    // segment criteria
    const [segmentCriteria, setSegmentCriteria] = useState("");
    // persona or archetype
    const [personaOrArchetype, setPersonaOrArchetype] = useState("Personas");


    // useEffect
    useEffect(() => {
        const fetchUser = async() => {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                setUser(amplifyUser);
                console.log(amplifyUser);
                if (amplifyUser.attributes.email.includes("suntory")) {
                    setPersonaOrArchetype("Archetypes");
                  }
            } catch (error) {
                setUser(null);
                console.log(error);
            }
        }
        fetchUser();
    }, []);

    // combine the ranges for net worth and income to single range for LLM
    function createCombinedRange(ranges) {
        // Function to convert monetary strings to numbers (in thousands)
        const convertToNumber = value => {
            if (value.endsWith('+')) {
                return parseFloat(value.slice(1, -1)) * 1000;
            }
            return value.endsWith('m') ? parseFloat(value.slice(1)) * 1000 : parseFloat(value.slice(1, -1));
        };
    
        // Extract all values and convert them to numbers, keeping track of open-ended ranges
        let isMaxOpenEnded = false;
        let allValues = ranges.flatMap(range => range.split('-').map(value => {
            if (value.endsWith('+')) {
                isMaxOpenEnded = true;
                return convertToNumber(value);
            }
            return convertToNumber(value);
        }));
    
        // Find the minimum and maximum values
        let minValue = Math.min(...allValues);
        let maxValue = Math.max(...allValues);
    
        // Function to convert numbers back to monetary strings
        const convertToString = value => {
            return value >= 1000 ? `$${value / 1000}m` : `$${value}K`;
        };
    
        // Formatting the range
        let formattedMin = convertToString(minValue);
        let formattedMax = isMaxOpenEnded ? convertToString(maxValue) + '+' : convertToString(maxValue);
    
        return formattedMin + " - " + formattedMax;
    }

    // create str from user data form for LLM
    const createStr = (data) => {
        //console.log("create segment instance using data - ", data)
        let res = "";
        //if (data.demo)
        if (data.gender){
            res += "gender: " + data.gender + "\n";
        }
        if (data.age) {
            const age = combineNumericRanges(data.age);
            res += "age range: " + age + "\n";
        }
        if (data.race) {
            res += "race: " + data.race + "\n";
        }
        if (data.education) {
            res += "education: " + data.education + "\n";
        }
        if (data.occupation) {
            res += "occupation: " + data.occupation + "\n";
        }
        if (data.maritalStatus) {
            res += "marital status: " + data.maritalStatus + "\n";
        }
        if (data.children) {
            res += "number of children living at home: " + data.children + "\n";
        }
        if (data.income) {
            const income  = createCombinedRange(data.income);
            res += "income range: " + income + "\n";
        }
        if (data.netWorth) {
            const netWorth = createCombinedRange(data.netWorth);
            res += "net_worth range: " + netWorth + "\n";
        }
        if (data.city) {
            res += "city: " + data.city + "\n";
        }
        if (data.state) {
            res += "state: " + data.state + "\n";
        }
        if (data.region) {
            res += "region: " + data.region + "\n";
        }
        if (data.country) {
            res += "country: " + data.country + "\n";
        }
        if (data.interests) {
            res += "interests: " + data.interests + "\n";
        }
        if (data.attitudes) {
            res += "attitudes: " + data.attitudes + "\n";
        }
        if (data.values) {
            res += "values: " + data.values + "\n";
        }
        if (data.lifestyle) {
            res += "lifestyle: " + data.lifestyle + "\n";
        }
        if (data.personality) {
            res += "personality: " + data.personality + "\n";
        }
        if (data.social) {
            res += "social: " + data.social + "\n";
        }
        //console.log(res)
        return res;
    }

    function parsePersonasJson(str, segmentID, data) {
        
        let personas;
        if (typeof str === 'string') {
            let correctedStr ="";
            // Check if Markdown syntax is present
            if (str.startsWith('```json') && str.endsWith('```')) {
                // Remove Markdown syntax
                correctedStr = str.replace(/```json\n/, '').replace(/\n```/, '');
            }
            // parse json string
            personas = JSON.parse(correctedStr);
        } else {
            personas = str;
        } 
        //console.log(personas)
        personas.forEach(persona => {
            persona.owner = user.attributes.email;
            persona.personaID = persona.name.replace(/\s+/g, '').toLowerCase();
            persona.segmentID = segmentID;
            persona.persona_type = "CONSUMER";
            persona.id = "temp-" + persona.personaID;
            persona.preference = "NEUTRAL";
            persona.userFeedback = "";
            persona.summary = persona.age + "; " + persona.gender + "; " + persona.race + "; " + persona.education + "; " + persona.occupation + "; " + persona.marital_status + "; " + persona.children + "; " + persona.netWorth + "; " + persona.income + "; " + persona.city + "; " + persona.state + "; " + persona.country + "; " + persona.values + "; " + persona.lifestyle + "; " + persona.interests + "; " + persona.attitudes + "; " + persona.personality + "; " + persona.social;
        });

        console.log("Persona strings: ", personas);

        return personas;
    }

    // Function to convert numeric strings to numbers
    // "18-24" - 18, 24
    const combineNumericRanges = (ranges) => {
        const convertToNumber = value => {
            if (value.endsWith('+')) {
                return parseInt(value.slice(0, -1));
            }
            return value.split('-').map(val => parseInt(val));
        };
        // Extract all values and convert them to numbers, keeping track of open-ended ranges
        let isMaxOpenEnded = false;
        let allValues = ranges.flatMap(range => {
            if (typeof range === 'string') {
                return range.split('-').map(value => {
                    if (value.endsWith('+')) {
                        isMaxOpenEnded = true;
                        return convertToNumber(value);
                    }
                    return convertToNumber(value);
                });
            } else {
                return [];
            }
        });
        // Find the minimum and maximum values
        let minValue = Math.min(...allValues);
        let maxValue = Math.max(...allValues);
        // Formatting the range
        let formattedMin = minValue;
        let formattedMax = isMaxOpenEnded ? maxValue + '+' : maxValue;
        return formattedMin + " - " + formattedMax;
    }
    
    const generateGroupedFields = (data) => {
        let group_field = "";

        if (data.gender){
            group_field += "gender: " + data.gender;
        }
        if (data.age) {
            const age = combineNumericRanges(data.age);            
            group_field += ", age range: " + age;
        }
        if (data.race){
            group_field += ", race: " + data.race;
        }
        if (data.education){
            group_field += ", education: " + data.education;
        }
        if (data.occupation){
            group_field += ", occupation: " + data.occupation;
        }
        if (data.maritalStatus){
            group_field += ", marital status: " + data.maritalStatus;
        }
        if (data.children){
            group_field += ", number of children living at home: " + data.children;
        }
        if (data.netWorth) {
            const netWorth = createCombinedRange(data.netWorth);            
            group_field += ", net worth range: " + netWorth;
        }
        if (data.income) {
            const income  = createCombinedRange(data.income);            
            group_field += ", income range: " + income;
        }
        if (data.city){
            group_field += ", city: " + data.city;
        }
        if (data.state){
            group_field += ", state: " + data.state;
        }
        if (data.region){
            group_field += ", region: " + data.region;
        }
        if (data.country){
            group_field += ", country: " + data.country;
        }
        if (data.interests) {            
            group_field += ", interests and hobbies: " + data.interests;
        }
        if (data.attitudes) {
            group_field += ", attitudes and opinions: " + data.attitudes;
        }
        if (data.values) {
            group_field += ", values and beliefs: " + data.values;
        }
        if (data.lifestyle) {
            group_field += ", lifestyle and activities: " + data.lifestyle;
        }
        if (data.personality) {
            group_field += ", personality: " + data.personality;
        }
        if (data.social) {
            group_field += ", social and cultural factors: " + data.social;
        }

        console.log("Grouped fields: ", group_field)
        return group_field;
    }

    const removePanelistsPII = (panelists) => {
        const panelistsCopy = [...panelists];
        panelistsCopy.forEach(panelist => {
            delete panelist.owner;
            delete panelist.panelID;
            delete panelist.id;
            delete panelist.createdAt;
            delete panelist.updatedAt;
            delete panelist.__typename;
            //delete panelist.backstory;
            // delete values that are null
            Object.keys(panelist).forEach(key => panelist[key] === null && delete panelist[key]);
        });
        return panelistsCopy;
    }

    const createSegments = async(data) => {

        console.log('Form submitted with data:', data);
        let result;

        const segmentDescStr = data.description;
        const user = await GetUser();
        console.log("Segment description: ", segmentDescStr)
        console.log("User: ", user.attributes.email)

        try {
            result = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: mutations.createSegments,
                variables: {
                    input: {
                        name: data.name,
                        description: segmentDescStr,
                        owner: user.attributes.email
                    }
                }
            });
        }
        catch(err) {
            console.error(err);
        }

        // get the segment id
        //const segmentID = "1";  // dummy data for test
        const segmentID = result.data.createSegments.id;
        setSegmentName(data.name);
        console.log("Segment ID: ", segmentID)     

        return segmentID;
    }

    const getPersonasFromLLM = async(data) => {
        const segmentID = data.segmentID;

        let system_prompt;
        let persona_prompt;
        let personas;

        // create new personas using LLM
        let grouped_fields = generateGroupedFields(data);
        setSegmentCriteria(grouped_fields); 
        
        if (data.creationProcess === "Panel") {
            // get the MR Assistant
            const assistantDB = await API.graphql({
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: queries.getLLMAssistant,
                variables: {
                    id: props.MRAssistantID,
                }
            });
            console.log("Assistant: ", assistantDB.data.getLLMAssistant);
            const LLMAssistant = assistantDB.data.getLLMAssistant.assistantID;
            console.log("Assistant ID: ", LLMAssistant);

            // create a thread for this persona creation process



            // create a thread
            let dataToLLM={}
            dataToLLM.model = model;
            dataToLLM.temperature = 0.5;
            dataToLLM.mode = "assistant";
            dataToLLM.llm_data = {
                assistantMode: "createThread",
            }
            const threadData =  await CreateThreadLLM(dataToLLM);
            // save thread to DB
            const threadDBData = {
                threadID: threadData.threadID,
                owner: user.attributes.email,
                assistantID: LLMAssistant,
            }
            console.log("Thread in DB data: ", threadDBData)
            const threadDBID = await SaveLLMThreadDB(threadDBData).catch(error => {
                console.error('Error saving LLM Thread:', error);
                console.error('Error details:', error.errors);
            });
            console.log("Thread in DB info: ", threadDBID);

            // get the panelists by panel id
            const panelists = await GetPanelistsbyPanelId(data.panel.id);
 
            // get the file IDs for each panelist from the database
            let updatedPanelists = [];
            
            let delay = 2000;
            //let fileIDs = [];
            let promises = panelists.map((panelist, index) => {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            //console.log("Panelist ID: ", panelist.id);
                            let response = await API.graphql({
                                authMode: "AMAZON_COGNITO_USER_POOLS",
                                query: queries.lLMAssistantsByPanelistID,
                                variables: {
                                    panelistID: panelist.id
                                }
                            })
                            //console.log("Response: ", response.data.lLMAssistantsByPanelistID.items);
                            let assistant_panel = null;
                            if (response.data.lLMAssistantsByPanelistID.items.length > 0) {
                                assistant_panel = response.data.lLMAssistantsByPanelistID.items[0];
                            }
                            //console.log("Assistant panel: ", assistant_panel);
                            let fileID = null;
                            if (assistant_panel && assistant_panel.fileID.length > 0) {
                                //console.log("Response: ", assistant_panel.fileID[0]);
                                fileID = assistant_panel.fileID[0];
                            }
                            //console.log("File ID: ", fileID);
                            resolve ({fileID, panelist});


                        }   catch (error) {
                                console.error("Error getting the LLM Assistant to get the file ID for this panelist: ", error);
                                reject(error)
                            }
                    }, index*delay);
                })
            })

            await Promise.all(promises)
                .then(panelistsWithFileIDs  => {
                    //savedPanelists = updatedPanelists;
                    //fileIDs = fIDS;
                    //console.log(panelistsWithFileIDs);
                    updatedPanelists = panelistsWithFileIDs;
                })
                .catch(error => {
                    console.error("Error updating panelist: ", error);
                })
            console.log("Updated panelists: ", updatedPanelists);
            let messageData = {};
            messageData.model = model;
            messageData.temperature = 0.5;
            messageData.mode = "assistant";
            const n_panelists = panelists.length;
            const n_personas = data.numberOfPersonas;
            messageData.llm_data = {
                assistantMode: "createMessage",
                threadID: threadData.threadID,
                assistantID: LLMAssistant,
                message: 'This is start of a customer segmentation project. Your objective is to segment the ' + n_panelists + ' respondents from a qualitative research into customer segments. ' + 
                        'You will be provided with the respondent profiles and interview transcripts one at a time. ' +
                        'You will identify key themes and patterns in the profiles and use these to create distinct personas.'
            };
            console.log("Message data: ", messageData);
            let messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse);
            for (let panelist of updatedPanelists) {
                // get the file id for the panelist
                const fileID = panelist.fileID;
                console.log("File ID: ", fileID);
                
                // get the panelist data without PII
                const panelistWithoutPII = removePanelistsPII([panelist.panelist])[0];
                console.log("Panelist without PII: ", panelistWithoutPII);

                console.log("Updated Panelist: ", panelist);

                
                messageData.llm_data = {
                    assistantMode: "createMessage",
                    threadID: threadData.threadID,
                    assistantID: LLMAssistant,
                    message: `                    
                    Here is the profile of one of the respondents and their corresponding interview transcript: ` + JSON.stringify(panelistWithoutPII),
                    file_ids: [fileID]
                };
                messageDataResponse = await CreateMessageLLM(messageData);
                console.log("Message response: ", messageDataResponse);
            }
            
            /* 
            * Determine number of personas to create
            */
           {/*
            messageData.llm_data = {
                assistantMode: "createMessage",
                threadID: threadData.threadID,
                assistantID: LLMAssistant,
                message: `Determine the number of personas to create for this segment. Return a single number and nothing else.
                    `
            };
            messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse);
            const n_personas = messageDataResponse[0].message;
            console.log("Number of personas: ", n_personas);
        */}
            messageData.llm_data = {
                assistantMode: "createMessage",
                threadID: threadData.threadID,
                assistantID: LLMAssistant,
                message: `Now generate ${n_personas} personas for this customer segment.                           
                            Return the personas in a list and no other text in JSON format: [<Persona 1>, <Persona 2>,...]
                            Each persona has the following fields in JSON format:
                            <Persona> :
                            {"name", "gender", "age", "race", "education", "occupation", "marital_status", "children", "netWorth", "income", "city", "state", "country", "values", "lifestyle", "interests", "attitudes", "personality", "social"}.
                            Here is the description of the fields:
                            {"name" : unique nickname composed of 2-3 words that is different from nicknames of other profiles, "gender" : gender, "age" : age range, "race" : race, "education" : education, "occupation" : occupation, "marital_status" : marital status, "children" : number of children, "netWorth" : net worth range in US dollars, "income" : income range in US dollars, "city" : city where persona lives, "state" : state of the city where persona lives, "country" : country of the state where persona lives, "values": 2-3 sentences describing values and beliefs of the persona, "lifestyle": 2-3 sentences sentences describing lifestyle and activities of the persona, "interests": 2-3 sentences describing interests and hobbies of the persona, "attitudes": 2-3 sentences describing attitudes and opinions of the persona, "personality": 2-3 sentences describing personality traits of the persona, "social": 2-3 sentences describing social and cultural factors that influence the persona}
                            Use pronouns such as "he", "she", "they" to refer to the persona.`
            };
            messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse[0]);
            personas = parsePersonasJson(messageDataResponse[0].message, segmentID, data);

            /*
            * Identify key factors for the segment creation
            */
            messageData.llm_data = {
                assistantMode: "createMessage",
                threadID: threadData.threadID,
                assistantID: LLMAssistant,
                message: `Based on the respondent information (demographics and interviews) and personas generated, identify key factors for the segment creation.
                        List the key factors in JSON format: {<factor 1>, <factor 2>,...}
                        `
            };
            messageDataResponse = await CreateMessageLLM(messageData);
            console.log("Message response: ", messageDataResponse);
           
            // create a new message for the thread
            //let removedPIIPanelists = removePanelistsPII(panelists);    
        
            //let segmentID = data.segmentID;
            //onst personas = parsePersonasJson(messageDataResponse, segmentID, data);
            //console.log("List of the personas: ");
            //console.log(personas);

            /*    
            let panelistsWithAnswers = [];
            for (let i=0; i<panelists.length; i++) {
                console.log("Panelist: ", panelists[i].id);
                const answers = await GetInsightAnswersByPanelistID(panelists[i].id);
                const panelistWithoutPII = removePanelistsPII([panelists[i]])[0];
                panelistWithoutPII.interview = answers;
                console.log(panelistWithoutPII);
                panelistsWithAnswers.push({panelist: panelistWithoutPII});
            }

            // get profiles of panelists as JSON string
            //const panelistsStr = JSON.stringify(removePanelistsPII(panelists));
            const panelistsWithAnswersStr = JSON.stringify(panelistsWithAnswers);
            console.log(panelistsWithAnswersStr);
            

            system_prompt = system_prompt_panel;
            //persona_prompt = "Here is list of the profiles to use: " + panelistsStr;
            persona_prompt = "Here is list of the profiles to use: " + panelistsWithAnswersStr;
            */

        } else if (data.creationProcess === "Criteria") {
            system_prompt = system_prompt_criteria;
            persona_prompt = "Generate unique personas based on the following criteria: " 
            + grouped_fields;

            console.log(persona_prompt)

            const LLMData = {
                question: persona_prompt,
                system_prompt: system_prompt,
                model: "gpt-3.5-turbo",
                temperature: 0.5,
                response_format: "simple"
            };
            const parsedData = await SimpleLLMStartPoll(LLMData);        
            console.log(parsedData);        
        
            //const personas = parsePersonas(parsedDataTitle, segmentID, data);
            personas = parsePersonasJson(parsedData, segmentID, data);

            //const personas = testPersonas;
            console.log("List of the personas: ",  personas)
        }

        return personas;
    }


    const generatePersonas = async(data) => {

        console.log('Form submitted with data:', data);
        
        setWorkflowStatus(WorkflowStatus.ploading);

        // create segment
        const segmentID = await createSegments(data);
        console.log("Segment ID: ", segmentID)
        setSegmentID(segmentID);
        
        // create new personas using LLM
        data.segmentID = segmentID;
        const personasFromLLM = await getPersonasFromLLM(data);
        console.log("Personas from LLM: ", personasFromLLM);
        setPersonaList(personasFromLLM);

        setWorkflowStatus(WorkflowStatus.results);
    };    

    // savePersonas
    const savePersonas = async(personas) => {
        // save the personas to the database
        let result;
        for (let persona of personas) {
            // remove the temporary id
            delete persona.id;
            console.log("Persona being sent to DB: ", persona);
            try {
                result = await API.graphql({
                    authMode: "AMAZON_COGNITO_USER_POOLS",
                    query: mutations.createCustomerPersona,
                    variables: {
                        input: persona,                        
                    }
                });
            }
            catch(err) {
                console.error(err);
            }
            persona.id = result.data.createCustomerPersona.id;
            console.log("Updated with the persona ID: ", persona)
        }

        // create LLM assistant for each persona
        let LLMData = {};
        LLMData.model = model;
        LLMData.temperature = 0.5;
        LLMData.mode = "assistant";
        let llm_data ={}
        let delay =2000;
        let promises = personas.map((persona, index) => {
            return new Promise((resolve, reject) => {
                setTimeout(async () => {
                    try {
                        // create a new LLM assistant for this persona
                        llm_data = {
                            assistantMode: "createAssistant",
                            name: segmentName + "-" + persona.name,
                            instructions: "You are a persona in a market research customer segment. Answer questions based on your profile" + JSON.stringify(removePII(persona)),
                        }
                        LLMData.llm_data = llm_data;
                        return CreateAssistantLLM(LLMData)
                            .then(response =>  {
                                //persona.assistantID = response.assistantID;
                                // save the assistant to the DB
                                const assistantDBData = {
                                    assistantID: response.assistantID,
                                    customerPersonaID: persona.id,
                                    owner: persona.owner,
                                    name: "asst-persona-"+persona.name,
                                }
                                return SaveLLMAssistantDB(assistantDBData)
                                        .then(id => {
                                            console.log("Assistant DB ID: ", id);
                                            return persona;
                                        })
                                        .catch(error => {
                                            console.error('Error saving LLM Assistant:', error);
                                            console.error('Error details:', error.errors);
                                        });
                                });
                            } catch (error) {
                                console.error("Error creating LLM Assistant: ", error);
                                
                            }
                        }, index*delay);
                    })
                    
                });

        Promise.all(promises)
                .then(updatedPersonas  => {
                    personas = updatedPersonas;
                })
                .catch(error => {
                    console.error("Error updating persona: ", error);
                })

        // replace the personas
        setPersonaList(personas)

        props.createNewPersona(personas);
    }

    // remove personal data
    const removePII = (persona) => {
        const personaCopy = {...persona};
        delete personaCopy.owner;
        delete personaCopy.segmentID;
        delete personaCopy.userFeedback;
        delete personaCopy.preference;
        delete personaCopy.persona_type;
        return personaCopy;
    }

    // regenerate a single persona
    const regeneratePersona = async(personaID) => {

        //find the persona from list of personas
        const persona = personaList.find(persona => persona.id === personaID);
        console.log("Regenerate this persona: ")
        console.log(persona);
        
        setWorkflowStatus(WorkflowStatus.ploading);

        // create new personas using LLM
        let grouped_fields = JSON.stringify(removePII(persona));
        console.log(grouped_fields)

        const personaPrompt = "Generate a unique persona or archetype. Make sure it is not similar to this one: " 
        + grouped_fields;
        console.log(personaPrompt)

        let system_prompt = system_prompt_criteria_single_persona;        

        const LLMData = {
            question: personaPrompt,
            system_prompt: system_prompt,
            model: model,
            temperature: 0.5,
            response_format: "simple"
        }
        const parsedPersonaFromLLM = await SimpleLLMStartPoll(LLMData); 
        //const parsedDataTitle = testData;
        console.log(parsedPersonaFromLLM);
        const personaObject = parsePersonasJson(parsedPersonaFromLLM, segmentID,"")[0];
        // add persona to the list 
        
        setPersonaList(prevPersonaList => [
            ...prevPersonaList.filter(persona => persona.id !== personaID), 
                personaObject]);
        console.log(personaList);
        setWorkflowStatus(WorkflowStatus.results);
        return personaObject;       
        //const personas = parsePersonas(parsedDataTitle, segmentID
    }

    const editPreference = async(personaID, likeStatus) => {
        console.log("Edit persona preference: ");
        console.log(personaID);
        console.log(likeStatus);
        // find the persona from list of personas
        const persona = personaList.find(persona => persona.id === personaID);
        persona.preference = likeStatus;
        setPersonaList(personaList);        
    }

    const regenerateAllPersonas = async() => {
        
        console.log("Regenerate all personas");
        setWorkflowStatus(WorkflowStatus.rloading);

        let grouped_fields = ""
        personaList.forEach(persona => {
            grouped_fields += JSON.stringify(removePII(persona));
        });
        console.log(grouped_fields)

        const persona_prompt = "Generate a number of unique personas for the following customer segment: " 
        + segmentCriteria + ". Make sure the personas are different from these:" + grouped_fields;
        let personasFromLLM;
        console.log(persona_prompt)
        let system_prompt = system_prompt_criteria;

        const LLMData = {
            question: persona_prompt,
            system_prompt: system_prompt,
            model: "gpt-3.5-turbo",
            temperature: 0.5,
            response_format: "simple"
        };
        const parsedData = await SimpleLLMStartPoll(LLMData);        
        console.log(parsedData);        
       
        //const personas = parsePersonas(parsedDataTitle, segmentID, data);
        const personas = parsePersonasJson(parsedData, segmentID, "");

        //const personas = testPersonas;
        console.log("List of the personas: ",  personas)        
        
        console.log(personas)

        setPersonaList(personas);

        setWorkflowStatus(WorkflowStatus.results);
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className="w-full h-full bg-slate-50">
                {workflowStatus === WorkflowStatus.brief && <PersonaBrief generatePersonas={generatePersonas}/>}
                {workflowStatus === WorkflowStatus.ploading && <div className="w-full h-full bg-slate-50 m-8 items-center content-center"> <h1 className="text-center align-middle">Generating {personaOrArchetype}...</h1> <Loading /> </div>}
                {workflowStatus === WorkflowStatus.rloading && <div className="w-full h-full bg-slate-50 m-8 items-center content-center"> <h1 className="text-center align-middle">Generating {personaOrArchetype}...</h1> <Loading /> </div>}    
                {workflowStatus === WorkflowStatus.results && <PersonaResults personaList={personaList} 
                    savePersonas={savePersonas} regeneratePersona={regeneratePersona} editPreference={editPreference}
                    regenerateAllPersonas={regenerateAllPersonas}/>}         
            </div>
        </div>
    );
}

export default NewPersonaWorkflow;
