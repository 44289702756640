import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import addIcon from "../../img/icons8-add-30.png"
import editIcon from "../../img/icons8-edit-30.png"

const Positioning = (props) => {

    const positioning = props.positioning;
    const messaging = props.messaging;
    const [isLoading, setIsLoading] = useState(props.isLoading);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    function addPositioning() {
        console.log("Positioning.addPositioning: " + positioning)
        navigate("positioning");
    }

    function editPositioning() {
        console.log("Positioning.editPositioning: " + positioning)
    }

    return (
        <div className="flex flex-row flex-wrap w-full py-8 px-8 mt-0 mb-2 ml-4 mr-4 space-y-0 justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
        <div className="w-full mb-4 py-8 px-8 space-y-0 bg-white justify-start rounded-xl drop-shadow-lg sm:py-4 sm:flex sm:items-center sm:space-y-4 sm:space-x-6">
            <div className="justify-items-start sm:text-left w-full">
                <div className="flex justify-between items-center">
                <h5 className="text-left font-medium">
                    Positioning & Messaging
                </h5>
                <div className="flex flex-row justify-end space-x-4">
                        <button disabled={false} className="rounded-lg items-center text-center text-slate-500 hover:text-blue-600" type="button" onClick={() => addPositioning()}> 
                            <img src={addIcon} alt="Add Icon" /> 
                        </button>
                        <button disabled={false} className="rounded-lg items-center text-center text-slate-500 hover:text-blue-600" type="button" onClick={() => editPositioning()}> 
                            <img src={editIcon} alt="Edit Icon" /> 
                        </button>
                    </div>
                </div>
                <hr />
                <ul className="text-left font-medium list-disc px-4">                            
                    <li key={positioning}><b>Positioning:</b> {positioning}</li>
                    <li key={messaging}><b>Messaging:</b> {messaging}</li>
                </ul>
                </div>
                </div>
                </div>
    )
}

export default Positioning;