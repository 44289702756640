import { API } from "aws-amplify";

async function getJobStatus(jobId) {
    const path = '/jobs/' + jobId;
    //let attempts = 0;
    let response;
    let delay = 1000;

    while (true) {
        response = await API.get('llmjobdevapi', path);
        console.log(response);

        if (response.status === 'completed') {
            //console.log('Job completed!', response);
            break;
        } else {
            //console.log('Job not yet completed');
            //attempts++;
            
            //let delay = Math.min(Math.pow(2, attempts) * 1000, 30000);
            //console.log(`Retrying in ${delay}ms...`);
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }
    console.log('Job completed!', response);

    return response;
}


export const CreateThreadLLM = async (data) => {
    console.log(data);
    const model = data.model;
    const temperature = data.temperature;
    const mode = data.mode;
    const dataToLLM = data.llm_data;
    let jobID;
    let attempts = 0;
    while (attempts < 3) {
        try {
            const response = await API.post('llmjobdevapi', '/jobs', {
                body : {
                    input : {
                        model: model,
                        temperature: temperature,
                        mode: mode,
                        data: dataToLLM
                    }
                }
            });
            jobID = response.jobID;
            console.log(response);
            break;
        }catch(error) {
            console.log(error);
            attempts++;
            await new Promise(resolve => setTimeout(resolve, 1000));
        };
    }

    if (!jobID) {
        console.log('Failed to create thread');
        return({ status: 'failed' });
    }
    
    // get the thread ID
    const thread_response = await getJobStatus(jobID);
    console.log(thread_response);
    const threadID_obj = JSON.parse(thread_response.result);
    let result = { threadID: threadID_obj.threadID };
    return result;
}




