import './App.css'

// Amplify AWS ui
import "@aws-amplify/ui-react/styles.css";
import {
  Authenticator,  
} from "@aws-amplify/ui-react";

// AWS Auth
//import { Auth } from 'aws-amplify';

// React Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// AWS Exports
import awsExports from "./aws-exports"
import { Auth, Amplify, API } from 'aws-amplify';
// mui license
import { LicenseInfo } from "@mui/x-license-pro";

// graphql 
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

// state
import { useState } from 'react';

// App files
import insytlogo from './insytlogo.png'
import Navbar from './components/navbar/Navbar';
import MainDashboard from './components/MainDashboard';
import PersonaDashboard from './components/personas/PersonaDashboard';
import ProjectDashboard from './components/projects/ProjectDashboard';
import PanelDashboard from './components/panels/PanelDashboard';
import { useEffect } from 'react';
import { CreateAssistantLLM } from './utils/llmcall/CreateAssistantLLM';

// axios 
//import axios from "axios"
//import Readability from "@mozilla/readability"
//import JSDOM from "jsdom"
//import CustomSignUp from './components/auth/CustomSignUp';

Amplify.configure(awsExports)
// Get user attributes from AWS Auth
/*Amplify.configure(awsExports)
const user = await Auth.currentAuthenticatedUser();
const {attributes} = user;
*/
let isFetching = false;

function App({signOut}) {  

  //const signOutButton = <Button onClick={signOut} color="#fff" >Sign Out</Button>;
  const version ="0.0.0.1"
  //console.log(version) 
  // mui license
  LicenseInfo.setLicenseKey("d965ea986cf34e59b78990b00be9a45fTz04NDU3NyxFPTE3Mzk4NDIxMTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

  const [MRAssistantID, setMRAssistantID] = useState(null);

  async function fetchMRAssistant() {
    console.log("Fetching MR Assistant")
    console.log("Assistant ID: " + MRAssistantID);
    console.log(isFetching)
      if (isFetching) {
        return;
      }
      isFetching = true;
     
      // check if MR assistant is already created, if not create it for this user
      let owner;
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        owner = userInfo.attributes.email;
      } catch (error) {
        console.log("No user is signed in")
        isFetching = false;
        return;
      }      
      
      let assistantName = "MR Assistant-" + owner;
      console.log(assistantName)
      let assistant=null;
  
      const apiData = await API.graphql({
          authMode: "AMAZON_COGNITO_USER_POOLS",
          query: queries.listLLMAssistants,
          variables: {
              filter: {
                  owner: {eq: owner}
              }
          }
      });
      // find if assistant with assistantName exists
      assistant = apiData.data.listLLMAssistants.items.find(item => item.name === assistantName);
      console.log(assistant)
      if (assistant) {
        console.log(assistant.id)
        setMRAssistantID(assistant.id);   
      } 
      /*else {
        // create new LLM Assistant with MR Assistant prefix and unique ID
        const LLMAssistantName = "MR Assistant-" + Math.random().toString(36).substring(2, 8);
        const data = {
            instructions: "You are a Market Research Assistant assistant with overal Market Resrach analysis for this user. Answer questions and analysis based on market research workflow",
            name: LLMAssistantName,
            assistantMode: "createAssistant",
        }
        const LLMData = {
            model: "gpt-3.5-turbo",
            temperature: 0.5,
            mode: "assistant",
            llm_data: data
        }
        const assistantData = await CreateAssistantLLM(LLMData);
        // save this info in the database
        const assistantInfo = {
            name: assistantName,
            assistantID: assistantData.assistantID,
            owner: owner
        }
        const apiData = await API.graphql({
            authMode: "AMAZON_COGNITO_USER_POOLS",
            query: mutations.createLLMAssistant,
            variables: {
                input: assistantInfo
            }
        });
        console.log(apiData);
        // save to assistantID
        console.log(assistantData.id)
        setMRAssistantID(assistantData.id);
      }*/

      isFetching = false;
      console.log("Assistant ID: " + MRAssistantID);
  }   

  useEffect(() => { 
    fetchMRAssistant();
    console.log("Assistant ID: " + MRAssistantID);
  }, [])

  return (   
      <div className="App">
        <Authenticator        
          components={{
            Header() {
              return(
                <div className="flex my-5 justify-center">
                  <img alt="Insyt Logo" src={insytlogo} />
                </div>
              )
            },
            Footer () {
              return (
                
                <div>
                  {/*<p className="flex justify-center text-xs">©2023 by Insyt Labs Inc.</p>*/}
                </div>
              )
            },
            SignUp: {
              
              FormFields() {            
                return (
                  <div className='p-2'>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />
    
                    {/* Append Terms & Conditions field to sign up  
                    <p className="sm-text"> 
                      By signing up, you agree with
                      our <a href="https://www.insyt.ai/terms" target="_blank" rel="noopener noreferrer" className='underline  text-slate-800 font-bold'>Terms & Conditions</a> and <a href="https://www.insyt.ai/privacy" target="_blank" rel="noopener noreferrer" className='underline text-slate-800 font-bold'>Privacy Policy</a>
                    </p>
                */}
                  </div>
                );
              },
            },
          }}        
        >
          {({signOut}) => (
            <BrowserRouter>
            <Navbar logo={insytlogo}
                  altlogo="Insyt Labs"
                  signOut={signOut}
            >
            <Routes>          
              <Route path="/*" element={<MainDashboard MRAssistantID={MRAssistantID}/>} />
              <Route path="/segments/*" element={<PersonaDashboard MRAssistantID={MRAssistantID}/>} />
              <Route path="/projects/*" element={<ProjectDashboard MRAssistantID={MRAssistantID}/>} />
              <Route path="/panels/*" element={<PanelDashboard MRAssistantID={MRAssistantID}/>} />
            </Routes>
            
            </Navbar>
          </BrowserRouter>  
          )}
        </Authenticator>       
        {/* Footer */}
        <div className="flex m-2 justify-center">
          <p className="flex justify-center text-xs">© {new Date().getFullYear()} Insyt Labs Inc.</p>
        </div>          
      </div>   

  )  
}
export default App

/*

<div>                    
                    <p className="sm-text"> By signing up, you agree with our 
                      <a href="https://www.insyt.ai/terms" target="_blank" className='underline  text-slate-800 font-bold'>Terms & Conditions</a>
                    </p>

                  </div>

*/

/*
const formFields ={
  signUp: {
    email: {
      order: 1,
    },
    password: {
      order: 2,
    },
    given_name: {
      order: 4,
      label: "First Name",
      placeholder: "Enter your first name",
    },
    family_name: {
      order: 5,
      label: "Last Name",
      placeholder: "Enter your last name",
    },    
  },
}
*/
/*
function App ( ) {
  return (
    <Authenticator formFields={formFields} >
      {({user, signOut}) => (
        <div>
          <h1>Hello </h1>
          <button onClick={signOut}> Sign Out </button>
        </div>
      )}
    </Authenticator>
  )
}
export default App;
*/

/*function App () {
  return (
    <div>
      <CustomSignUp />
    </div>
      
    
  )
}
export default App;
*/
// <Route path="/" element={<Dashboard />} />

